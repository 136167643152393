export const months = [
    {id: 1, name: 'Styczeń'},
    {id: 2, name: 'Luty'},
    {id: 3, name: 'Marzec'},
    {id: 4, name: 'Kwiecień'},
    {id: 5, name: 'Maj'},
    {id: 6, name: 'Czerwiec'},
    {id: 7, name: 'Lipiec'},
    {id: 8, name: 'Sierpień'},
    {id: 9, name: 'Wrzesień'},
    {id: 10, name: 'Październik'},
    {id: 11, name: 'Listopad'},
    {id: 12, name: 'Grudzień'}
];
