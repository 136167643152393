import React from 'react';
import { setView } from "../actions/index";
import { connect } from "react-redux";
import '../styles/Andra.scss';

class Andra extends React.Component {

    /**
     * Constructor
     */
    constructor(props) {
        super(props);
        this.state = {
            id: Math.floor((Math.random() * 5000) + 2000),
            activeIcon: this.props.activeIcon !== undefined && this.props.activeIcon === true
        };
    }

    openMenu = () => {
        if (this.state.activeIcon) {
            this.props.setView('menu');
        }
    }

    /**
     * @returns {XML}
     */
    render() {
        return (
          <div className="andra" onClick={this.openMenu}>
            <div className="scene" id={"scene" + this.state.id}>
              <div className="shape pyramid-1 pyr-1">
                <div className="face-wrapper ft">
                  <div className="face" />
                </div>
                <div className="face-wrapper bk">
                  <div className="face"></div>
                </div>
                <div className="face-wrapper lt">
                  <div className="face"></div>
                </div>
                <div className="face-wrapper rt">
                  <div className="face"></div>
                </div>
                <div className="face bm"></div>
              </div>
              <div className="shape pyramid-2 pyr-2">
                <div className="face-wrapper ft">
                  <div className="face"></div>
                </div>
                <div className="face-wrapper bk">
                  <div className="face"></div>
                </div>
                <div className="face-wrapper lt">
                  <div className="face"></div>
                </div>
                <div className="face-wrapper rt">
                  <div className="face"></div>
                </div>
                <div className="face bm"></div>
              </div>
              <div className="shape pyramid-3 pyr-3">
                <div className="face-wrapper ft">
                  <div className="face"></div>
                </div>
                <div className="face-wrapper bk">
                  <div className="face"></div>
                </div>
                <div className="face-wrapper lt">
                  <div className="face"></div>
                </div>
                <div className="face-wrapper rt">
                  <div className="face"></div>
                </div>
                <div className="face bm"></div>
              </div>
              <div className="shape pyramid-4 pyr-4">
                <div className="face-wrapper ft">
                  <div className="face"></div>
                </div>
                <div className="face-wrapper bk">
                  <div className="face"></div>
                </div>
                <div className="face-wrapper lt">
                  <div className="face"></div>
                </div>
                <div className="face-wrapper rt">
                  <div className="face"></div>
                </div>
                <div className="face bm"></div>
              </div>
              <div className="shape pyramid-5 pyr-5">
                <div className="face-wrapper ft">
                  <div className="face"></div>
                </div>
                <div className="face-wrapper bk">
                  <div className="face"></div>
                </div>
                <div className="face-wrapper lt">
                  <div className="face"></div>
                </div>
                <div className="face-wrapper rt">
                  <div className="face"></div>
                </div>
                <div className="face bm"></div>
              </div>
            </div>
          </div>
        );
    }

    componentDidMount() {
        let scene = document.getElementById('scene' + this.state.id);
        let rotateY = 0;

        var that = this;
        setInterval(function() {
            scene.style.transform = 'rotateX(-15deg) rotateY(' + rotateY + 'deg)';

            if (that.props.andra === 2) {
                rotateY += 2;
            }

            if (rotateY++ >= 360) {
                rotateY = 0;
            }
        }, 15);
    }
}

const mapStateToProps = (state) => {
    return {
        andra: state.andra
    }
};

export const AndraContainer = connect(mapStateToProps, { setView })(Andra);