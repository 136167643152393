import React from 'react';
import axios from "axios";
import ScreenComponent from './ScreenComponent';
import { setView, alert } from '../../actions';
import { connect } from "react-redux";
import { config } from "../../app/config";
import '../../styles/elements/screen.scss';
import '../../styles/elements/app.scss';

export class MenuScreen extends ScreenComponent
{
    /**
     * @param props
     */
    constructor(props)
    {
        super(props);

        this.state = {
            previousScreen: null
        };
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.view !== this.getScreenName() && this.props.view === this.getScreenName()) {
            this.setState({previousScreen: prevProps.view});
        }
    }

    /**
     * @returns {string}
     */
    getScreenName() {
        return 'menu';
    }

    /**
     * @returns {object}
     */
    financeTileStyle() {
        if (this.props.user === null || this.props.user.subscription === 'standard') {
            return {display: 'none'}
        }

        return {display: 'inline-block'};
    };

    /**
     * @returns {object}
     */
    todoTileStyle() {
        if (this.props.user === null || this.props.user.subscription === 'standard') {
            return {display: 'none'}
        }

        return {display: 'inline-block'};
    };

    /**
     * @returns {object}
     */
    nyumbaTileStyle() {
        if (this.props.user === null || this.props.user.imid.indexOf('nyumba') < 0) {
            return {display: 'none'}
        }

        return {display: 'inline-block'};
    };

    /**
     * Authorize login
     */
    authorizeIMidLogin = () => {
        this.props.setView(this.state.previousScreen);

        let that = this;
        axios.post(config.apiAddress + '/app/imid/authorize')
            .then(function (response) {
                that.props.alert('Autoryzowano logowanie');
            }).catch(function(error) {
                that.props.alert('Nie udało się autoryzować logowania');
            });
    };

    /**
     * @returns {XML}
     */
    render() {
        return (
            <div className={this.getClass() + ' app imd-bg-transparent-default'} style={this.getStyle()}>
                <button className="imd-nav-icon imd-bg-transparent-main" onClick={() => { this.props.setView('main')}}>
                    <span className="imd-icon imd-icon-home" />
                </button>
                <button className="imd-nav-icon imd-bg-transparent-main" onClick={() => { this.props.setView('note')}}>
                    <span className="imd-icon imd-icon-pencil" />
                </button>
                <button className="imd-nav-icon imd-bg-transparent-main" onClick={() => { this.props.setView('calendar')}}>
                    <span className="imd-icon imd-icon-calendar" />
                </button>
                <button className="imd-nav-icon imd-bg-transparent-main" onClick={() => { this.props.setView('finance')}} style={this.financeTileStyle()}>
                    <span className="imd-icon imd-icon-credit-card" />
                </button>
                <button className="imd-nav-icon imd-bg-transparent-main" onClick={() => { this.props.setView('fit')}}>
                    <span className="imd-icon imd-icon-trophy" />
                </button>
                <button className="imd-nav-icon imd-bg-transparent-main" onClick={() => { this.props.setView('recipes')}}>
                    <span className="imd-icon imd-icon-spoon-knife" />
                </button>
                <button className="imd-nav-icon imd-bg-transparent-main" onClick={() => { this.props.setView('todo')}} style={this.todoTileStyle()}>
                    <span className="imd-icon imd-icon-list" />
                </button>
                <button className="imd-nav-icon imd-bg-transparent-main" onClick={() => { this.props.setView('nyumba')}} style={this.nyumbaTileStyle()}>
                    <span className="imd-icon imd-icon-nyumba" />
                </button>
                <button className="imd-nav-icon imd-bg-transparent-main" onClick={() => { this.authorizeIMidLogin()}}>
                    <span className="imd-icon imd-icon-key" />
                </button>
                <button className="imd-nav-icon imd-bg-transparent-main" onClick={() => { this.props.setView('settings')}}>
                    <span className="imd-icon imd-icon-wrench" />
                </button>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        email: state.login.email,
        device: state.login.device,
        user: state.user,
        view: state.view
    }
};

export const MenuScreenContainer = connect(mapStateToProps, { setView, alert })(MenuScreen);
