import React from 'react';

export class RecipesList extends React.Component {
    /**
     * @returns {*}
     */
    mainStyle = () => {
        if (this.props.subscreen === 'list') {
            return {display: 'block'}
        }

        return {display: 'none'}
    };

    /**
     * @returns {[]}
     */
    renderData = () => {
        let rows = [];

        let that = this;
        Object.keys(this.props.recipes).forEach(function(key) {
            rows.push(<div className="imd-shadow recipe" key={key}>
                <span className="imd-border-color-accent imd-text-main"
                      onClick={() => that.props.openRecipe(that.props.recipes[key].id)}>
                    {that.props.recipes[key].name}
                </span>
            </div>);
        });

        return rows;
    };

    /**
     * @returns {XML}
     */
    render() {
        return (
            <div style={this.mainStyle()}>
                <button className="imd-button imd-button-outline imd-border-color-main imd-text-main"
                    onClick={() => this.props.openSubscreen('edit', true)}>
                    Nowy przepis
                </button>

                <hr />

                {this.renderData()}
            </div>
        );
    }
}
