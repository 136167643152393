import React from 'react';
import axios from 'axios';
import { Confirmation } from '../../Confirmation';
import { config } from '../../../app/config';
import { connect } from "react-redux";
import { preloaderShow, preloaderHide, alert } from '../../../actions';

export class CalendarEvent extends React.Component {
    /**
     * @param props
     */
    constructor(props) {
        super(props);

        let date = this.props.selectedDate;

        this.state = {
            loaded: false,
            date: date.getFullYear() + '-' + (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)) + '-' + (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()),
            time: (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':' + (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()),
            length: 60,
            participants: '',
            place: '',
            type: 'event',
            repeat: '',
            description: '',
            delete_event: null
        }
    }

    /**
     * @param prevProps
     * @param prevState
     */
    componentDidUpdate(prevProps, prevState) {
        if (this.props.eventId !== null) {
            if (!this.state.loaded) {
                this.openEvent();
            }
        }
    };

    /**
     * Load events for week
     */
    openEvent = () => {
        this.setState({loaded: true});
        if (Array.isArray(this.props.eventId)) {
            if (this.props.eventId[1] === null) {
                let date = this.props.selectedDate;
                this.props.eventId[1] = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours());
            }

            this.setState({
                date: this.props.eventId[0],
                time: this.props.eventId[1] + ':00',
                length: 60,
                participants: '',
                place: '',
                type: 'event',
                repeat: '',
                description: ''
            });

            return;
        }

        this.props.preloaderShow();
        let that = this;

        axios.get(config.apiAddress + '/app/calendar/event/' + that.props.eventId)
            .then(function (response) {
                let date = new Date(response.data.time * 1000);

                that.setState({
                    date: date.getFullYear() + '-' + (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1)) + '-' + (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()),
                    time: (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':' + (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()),
                    length: response.data.length,
                    participants: response.data.participants ? response.data.participants : '',
                    place: response.data.place ? response.data.place : '',
                    type: response.data.type,
                    repeat: response.data.repeat ? response.data.repeat : '',
                    description: response.data.description
                });
                that.props.preloaderHide();
            }).catch(function(error) {
                that.props.preloaderHide();
                that.props.alert('Nie udało się załadować wydarzenia');
            });
    };

    /**
     * Load events for week
     */
    saveEvent = () => {
        this.props.preloaderShow();
        let that = this;
        let data = {
            date: that.state.date,
            time: that.state.time,
            length: that.state.length,
            type: that.state.type,
            description: that.state.description,
            repeat: that.state.repeat,
            participants: that.state.participants,
            place: that.state.place
        }

        if (!Array.isArray(this.props.eventId)) {
            axios.put(config.apiAddress + '/app/calendar/event/' + this.props.eventId, data)
                .then(function (response) {
                    //I'm not hide poreloader because it will be hidden by CalendarEventsContainer after events will be reloaded.
                    that.props.alert('Zmiany zostały zapisane');
                    that.props.closeEvent();
                    that.props.changeDate(new Date(that.props.selectedDate));
                    that.setState({loaded: false});
                }).catch(function(error) {
                that.props.preloaderHide();
                that.props.alert('Nie udało się zapisać wydarzenia');
            });

            return;
        }

        axios.post(config.apiAddress + '/app/calendar/event', data)
            .then(function (response) {
                //I'm not hide poreloader because it will be hidden by CalendarEventsContainer after events will be reloaded.
                that.props.alert('Zmiany zostały zapisane');
                that.props.closeEvent();
                that.props.changeDate(new Date(that.props.selectedDate));
                that.setState({loaded: false});
            }).catch(function(error) {
                that.props.preloaderHide();
                that.props.alert('Nie udało się zapisać wydarzenia');
            });
    };

    /**
     * Load events for week
     */
    deleteEvent = () => {
        this.setState({delete_event: config.apiAddress + '/app/calendar/event/' + this.props.eventId});
    };

    /**
     * @param response
     * @param address
     */
    deleteEventSuccess = (response, address) => {
        //I'm not hide poreloader because it will be hidden by CalendarEventsContainer after events will be reloaded.
        this.props.alert('Zmiany zostały zapisane');
        this.props.closeEvent();
        this.props.changeDate(new Date(this.props.selectedDate));
        this.setState({loaded: false});
    };

    /**
     * @param error
     * @param address
     */
    deleteEventError = (error, address) => {
        this.props.alert('Nie udało się usunąć wydarzenia');
    };

    /**
     * @returns {*}
     */
    isOpen = () => {
        return (this.props.eventId === null ? null : ' opened');
    };

    /**
     * @returns {{float: string}}
     */
    getDeleteButtonStyle = () => {
        let style = {float: 'left'};

        if (this.props.eventId === 0) {
            style.display = 'none';
        }

        return style;
    }

    handleTypeChange = (event) => {
        if (event.target.checked) {
            this.setState({type: 'remainder'})
        } else {
            this.setState({type: 'event'})
        }
    }

    /**
     * @returns {XML}
     */
    render() {
        return (
            <div>
                <dialog className={"imd-dialog imd-bg-transparent-gray" + this.isOpen()}>
                    <div className="imd-dialog-body imd-bg-contrast">
                        <header>
                            Wydarzenie
                            <span className="imd-icon imd-icon-cross imd-dialog-close" onClick={() => {this.props.closeEvent(); this.setState({loaded: false});}} />
                        </header>

                        <div className="imd-dialog-content">
                            <div className="imd-row">
                                <label className="imd-col-sm-4 imd-col-xs-6">
                                    Data
                                    <input type="date" className="imd-form-control imd-border-color-main imd-text-main" value={this.state.date} onChange={e => {this.setState({date: e.target.value});}} />
                                </label>
                                
                                <label className="imd-col-sm-4 imd-col-xs-6">
                                    Czas
                                    <input type="time" className="imd-form-control imd-border-color-main imd-text-main" value={this.state.time} onChange={e => {this.setState({time: e.target.value});}} />
                                </label>

                                <label className="imd-col-sm-4 imd-col-xs-12">
                                    Czas trwania
                                    <select className="imd-form-control imd-border-color-main imd-bg-20-silver imd-text-main" value={this.state.length} onChange={e => {this.setState({length: e.target.value});}}>
                                        <option value="15">15 min.</option>
                                        <option value="30">30 min.</option>
                                        <option value="45">45 min.</option>
                                        <option value="60">Godzinę</option>
                                        <option value="90">1.5 godziny</option>
                                        <option value="120">2 godziny</option>
                                        <option value="180">3 godziny</option>
                                        <option value="240">4 godziny</option>
                                        <option value="300">5 godzin</option>
                                        <option value="360">6 godzin</option>
                                        <option value="1440">Cały dzień</option>
                                    </select>
                                </label>

                                <div className="imd-col-xs-12" style={{display: (this.props.user === null || this.props.user.subscription === 'standard') ? 'none' : 'block', marginBottom: '13px'}}>
                                    <input
                                        type="checkbox"
                                        id="calendar-remainder-checkbox"
                                        className="imd-form-checkbox"
                                        checked={this.state.type === 'remainder'}
                                        onChange={this.handleTypeChange}
                                    />
                                    <label htmlFor="calendar-remainder-checkbox" className="imd-text-main">
                                        <span className="imd-border-color-main" />
                                        Przypomnienie SMS
                                    </label>
                                </div>

                                <label className="imd-col-xs-12">
                                    Opis
                                    <textarea className="imd-form-control imd-border-color-main imd-text-main imd-bg-20-silver" value={this.state.description} onChange={e => {this.setState({description: e.target.value});}} />
                                </label>
                            </div>

                            <div className="imd-row">
                                <label className="imd-col-xs-12">
                                    Powtarzanie
                                    <select className="imd-form-control imd-border-color-main imd-bg-20-silver imd-text-main" value={this.state.repeat} onChange={e => {this.setState({repeat: e.target.value});}}>
                                        <option value="">&nbsp;</option>
                                        <option value="daily">Codziennie</option>
                                        <option value="weekly">Co tydzień</option>
                                        <option value="monthly">Co miesiąc</option>
                                        <option value="yearly">Co rok</option>
                                    </select>
                                </label>
                            </div>

                            <div className="imd-row">
                                <label className="imd-col-md-6 imd-col-xs-12">
                                    Uczestnicy
                                    <input type="text" className="imd-form-control imd-border-color-main imd-text-main" value={this.state.participants} onChange={e => {this.setState({participants: e.target.value});}} />
                                </label>
                                <label className="imd-col-md-6 imd-col-xs-12">
                                    Miejsce
                                    <input type="text" className="imd-form-control imd-border-color-main imd-text-main" value={this.state.place} onChange={e => {this.setState({place: e.target.value});}} />
                                </label>
                            </div>
                        </div>

                        <footer>
                            <button className="imd-button imd-button-outline imd-border-color-red imd-text-red" style={this.getDeleteButtonStyle()} onClick={this.deleteEvent}>
                                Usuń
                            </button>
                            <button className="imd-button imd-button-outline imd-border-color-default imd-text-default" onClick={() => {this.props.closeEvent(); this.setState({loaded: false});}}>
                                Anuluj
                            </button>
                            <button className="imd-button imd-button-outline imd-border-color-main imd-text-main" onClick={this.saveEvent}>
                                Zapisz
                            </button>
                        </footer>
                    </div>
                </dialog>

                <Confirmation
                    address={this.state.delete_event}
                    onSuccess={this.deleteEventSuccess}
                    onError={this.deleteEventError}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        email: state.login.email,
        device: state.login.device,
        user: state.user
    }
};

export const CalendarEventContainer = connect(mapStateToProps, {preloaderShow, preloaderHide, alert})(CalendarEvent);
