import React from 'react';
import { connect } from "react-redux";

export class SplashScreen extends React.Component {
    /**
     * Constructor
     */
    constructor(props) {
        super(props);
        this.state = {
            show: false
        };
    }

    /**
     * @returns {string}
     */
    getClassName() {
        return 'splash-screen' + (this.props.splashScreen ? '' : ' inactive');
    }

    /**
     * @returns {XML}
     */
    render() {
        return (
            <div className={this.getClassName()}>
                <svg
                    width="150"
                    height="171"
                    viewBox="0 0 39.687499 45.243751"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg">
                    <g
                        id="layer1">
                        <path
                            style={{fill: '#ff6400'}}
                            d="m 16.933333,20.902083 c 4.762502,8.113888 9.525001,16.227778 14.2875,24.341667 H 3.175 Z"
                            id="path364"/>
                        <path
                            style={{fill: '#ff6400'}}
                            d="m 29.104167,0 c 3.478615,6.1736099 6.957226,12.347222 10.435838,18.520834 H 19.054843 Z"
                            id="path364-3"/>
                        <path
                            style={{fill: '#ff8300'}}
                            d="M 9.2604167,9.2604167 C 10.999724,12.347221 12.73903,15.434027 14.478336,18.520833 H 4.2357547 Z"
                            id="path364-3-6"/>
                        <path
                            style={{fill: '#ffa000'}}
                            d="M 7.6032543,33.866667 C 5.0688363,29.36875 2.5344183,24.870833 2.5000001e-7,20.372916 H 14.924905 Z"
                            id="path364-3-6-7"/>
                        <path
                            style={{fill: '#ff8300'}}
                            d="M 22.628002,26.722917 C 21.435334,24.60625 20.242667,22.489584 19.05,20.372917 h 7.023484 z"
                            id="path364-3-6-7-5"/>
                    </g>
                </svg>

                <div className="madeby-mateusz-kolasa">
                    made by
                    <img src="images/logo-mateuszkolasa.svg" alt="Mateusz Kolasa"/>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        splashScreen: state.splashScreen
    }
};
const mapDispatchToProps = {};

export const SplashScreenContainer = connect(mapStateToProps, mapDispatchToProps)(SplashScreen);
