import React from 'react';
import ScreenComponent from './ScreenComponent';
import { connect } from "react-redux";
import { preloaderShow, preloaderHide, alert } from "../../actions";
import { config } from "../../app/config";
import axios from "axios";
import { ToDoNewListContainer } from "./todo/ToDoNewListContainer";
import '../../styles/elements/screen.scss';
import '../../styles/elements/app.scss';
import '../../styles/elements/screens/todo.scss';
import {Confirmation} from "../Confirmation";

export class ToDoScreen extends ScreenComponent
{
    /**
     * @param props
     */
    constructor(props)
    {
        super(props);

        this.state = {
            new: false,
            newItems: {},
            lists: {},
            deleteAddress: null
        };
    };

    /**
     * @param prevProps
     * @param prevState
     */
    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.view !== this.getScreenName() && this.props.view === this.getScreenName()) {
            this.loadData();
        }
    };

    /**
     * @returns {string}
     */
    getScreenName() {
        return 'todo';
    }

    /**
     * Load data
     */
    loadData = () => {
        this.props.preloaderShow();
        let that = this;

        that.setState({lists: []});
        axios.get(config.apiAddress + '/app/todo/lists')
            .then(function (response) {
                that.setState({lists: response.data});
                that.props.preloaderHide();
            }).catch(function(error) {
                that.props.alert('Nie udało się załadować danych');
                that.props.preloaderHide();
            });
    };

    /**
     * @returns {[]}
     */
    renderLists = () => {
        let lists = [];
        let that = this;

        Object.keys(this.state.lists).forEach(function(key) {
            lists.push(<div className="imd-col-xs-12 imd-col-md-6 imd-col-lg-4 " key={key}>
                <div className="imd-shadow-dark list">
                    <h1 className="imd-text-main">
                        {that.state.lists[key].name}
                        <span className="imd-icon imd-icon-bin imd-text-accent delete-icon"
                              onClick={() => that.removeList(that.state.lists[key].id)}
                        />
                    </h1>
                    {that.renderItems(that.state.lists[key])}
                </div>
            </div>);
        });

        return (lists);
    }

    /**
     * @returns {[]}
     */
    renderItems = (list) => {
        let items = [];
        let that = this;

        for (let x = 0; x < list.items.length; x++) {
            items.push(<div className={'imd-shadow imd-text-accent item' + that.getItemClass(list.items[x].checked)} key={x} onClick={() => that.changeStatus(list.id, x)}>
                {(list.items[x].checked ? <span className="imd-icon imd-icon-checkbox-checked" /> : <span className="imd-icon imd-icon-checkbox-unchecked" />)}
                &nbsp;
                {list.items[x].name}
            </div>);
        }

        items.push(<div className="imd-shadow imd-text-accent item" key={list.id + '_new'}>
            <span className="imd-icon imd-icon-checkbox-unchecked" />
            &nbsp;
            <input
                className="imd-form-control imd-border-color-main imd-text-main"
                placeholder="Nowy element"
                onChange={event => this.handleNewItemChange(list.id, event)}
                onKeyDown={event => this.handleNewItemEnter(list.id, event)}
            />
        </div>)

        return (items);
    }

    /**
     * @param listId
     * @param event
     */
    handleNewItemChange = (listId, event) => {
        let state = {}
        state[listId] = event.target.value;

        this.setState({newItems: state});
    }

    /**
     * @param listId
     * @param event
     */
    handleNewItemEnter = (listId, event) => {
        if (event.key === 'Enter') {
            this.createItem(listId, this.state.newItems[listId]);
        }
    };

    /**
     * @returns string
     */
    getItemClass = (checked) => {
        if (checked) {
            return ' item-checked';
        }

        return '';
    }

    /**
     * @param listId
     * @param item
     */
    createItem = (listId, item) => {
        let that = this;

        axios.post(config.apiAddress + '/app/todo/list/' +  listId + '/items/new', {name: item})
            .then(function (response) {
                that.loadData();
                let state = {}
                state[listId] = item;
                that.setState({newItems: state});
            }).catch(function(error) {
                that.props.alert('Nie udało się utworzyć elementu');
                that.props.preloaderHide();
            });
    }

    /**
     * @param listId
     * @param item
     */
    changeStatus = (listId, item) => {
        let that = this;

        axios.patch(config.apiAddress + '/app/todo/item/change-status/' + listId + '/' + item)
            .then(function (response) {
                let state = that.state;

                for (let x = 0; x < state.lists.length; x++) {
                    if (state.lists[x].id === listId) {
                        state.lists[x].items[item].checked = !state.lists[x].items[item].checked;
                    }
                }

                that.setState(state);
            }).catch(function(error) {
                console.log(error);
                that.props.alert('Nie udało się zmienić zaznaczenia');
                that.props.preloaderHide();
            });
    }

    /**
     * Close dialog with new list name
     */
    closeNewListDialog = () => {
        this.setState({new: false});
        this.loadData();
    }

    /**
     * @param listId
     */
    removeList = (listId) => {
        this.setState({deleteAddress: config.apiAddress + '/app/todo/list/' + listId})
    }

    /**
     * @param response
     * @param address
     */
    deleteListSuccess = (response, address) => {
        this.props.alert('Lista została usunięta');
        this.loadData();
    };

    /**
     * @param error
     * @param address
     */
    deleteListError = (error, address) => {
        this.props.alert('Nie udało się usunąć wydarzenia');
    };

    /**
     * @param error
     * @param address
     */
    deleteListCancel = (error, address) => {
        this.setState({deleteAddress: null})
    };

    /**
     * @returns {XML}
     */
    render() {
        return (
            <div className={this.getClass() + ' app imd-bg-transparent-default'} style={this.getStyle()}>
                <div className="title imd-bg-transparent-accent imd-text-default">
                    Listy ToDo
                </div>

                <div className="content">
                    <button className="imd-button imd-button-outline imd-border-color-main imd-text-main"
                        onClick={() => this.setState({new: true})}>
                        Nowa lista
                    </button>

                    <hr />

                    <div className="imd-row">
                        {this.renderLists()}
                    </div>
                </div>

                <ToDoNewListContainer
                    show={this.state.new}
                    closeNewListDialog={this.closeNewListDialog}
                />

                <Confirmation
                    address={this.state.deleteAddress}
                    onSuccess={this.deleteListSuccess}
                    onCancel={this.deleteListCancel}
                    onError={this.deleteListError}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        view: state.view
    }
};

export const ToDoScreenContainer = connect(mapStateToProps, { preloaderShow, preloaderHide, alert })(ToDoScreen);
