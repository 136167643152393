import React from 'react';
import ScreenComponent from './ScreenComponent';
import axios from 'axios';
import { config } from '../../app/config';
import { connect } from "react-redux";
import { preloaderShow, preloaderHide, alert } from '../../actions';
import '../../styles/elements/screen.scss';
import '../../styles/elements/screens/nyumba.scss';
import '../../styles/elements/app.scss';

export class NyumbaScreen extends ScreenComponent
{
    /**
     * @param props
     */
    constructor(props)
    {
        super(props);

        this.state = {
            masters: [],
            master: null,
            scenes: ''
        };
    };

    /**
     * @returns {string}
     */
    getScreenName() {
        return 'nyumba';
    };

    /**
     * @param prevProps
     * @param prevState
     */
    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.view !== this.getScreenName() && this.props.view === this.getScreenName()) {
            this.loadDevices();
        }
    };

    /**
     * Load all devices
     */
    loadDevices = () => {
        this.props.preloaderShow();
        let that = this;

        axios.get(config.apiAddress + '/app/nyumba/masters')
            .then(function (response) {
                that.setState({masters: response.data, master: Object.keys(response.data)[0]});
                that.loadScenes();
            }).catch(function(error) {
                that.props.alert('Nie udało się załadować urządzeń');
                that.props.preloaderHide();
            });
    };

    /**
     * Load all devices
     */
    loadScenes = () => {
        let that = this;

        axios.get(config.apiAddress + '/app/nyumba/master/' + this.state.master + '/scenes')
            .then(function (response) {
                that.setState({scenes: response.data});
                that.props.preloaderHide();
            }).catch(function(error) {
                that.props.alert('Nie udało się załadować scen');
                that.props.preloaderHide();
            });
    };

    /**
     * Load all devices
     */
    runScene = (id) => {
        let that = this;

        axios.post(config.apiAddress + '/app/nyumba/master/' + this.state.master + '/instructions', {'instructions': ['SCENE: ' + id]})
            .then(function (response) {
                that.props.alert('Scena zostanie uruchomiona');
            }).catch(function(error) {
                that.props.alert('Nie udało się uruchomić sceny');
            });
    };

    /**
     * @param id
     */
    setMaster = (id) => {
        this.setState({master: id});
        this.props.preloaderShow();
        this.loadScenes();
    };

    /**
     * @returns {Array}
     */
    renderMasters = () => {
        let masters = [];

        let that = this;
        Object.keys(this.state.masters).forEach(function(key) {
            if (key === that.state.master) {
                masters.push(<button key={key} className="imd-button imd-button-outline imd-border-color-main imd-text-main" onClick={() => that.setMaster(that.state.masters[key].id)}>
                    {(that.state.masters[key].name === null ? that.state.masters[key].id : that.state.masters[key].name)}
                </button>);
            } else {
                masters.push(<button key={key} className="imd-button imd-button-outline imd-border-color-accent imd-text-accent" onClick={() => that.setMaster(that.state.masters[key].id)}>
                    {(that.state.masters[key].name === null ? that.state.masters[key].id : that.state.masters[key].name)}
                </button>);
            }
        });

        return masters;
    };

    /**
     * @returns {Array}
     */
    renderScenes = () => {
        let scenes = [];

        let that = this;
        Object.keys(this.state.scenes).forEach(function(key) {
            scenes.push(<div key={key} className="imd-shadow screen-nyumba-scene">
                {that.state.scenes[key].name}
                <button>
                    <span className="imd-icon imd-icon-play3 imd-text-main" onClick={() => that.runScene(key)} />
                </button>
            </div>);
        });

        return scenes;
    };

    /**
     * @returns {XML}
     */
    render() {
        return (
            <div className={this.getClass() + ' app imd-bg-transparent-default'} style={this.getStyle()}>
                <div className="title imd-bg-transparent-accent imd-text-default">
                    Nyumba
                </div>

                <div className="content imd-text-accent">
                    <div className="actions">
                        {this.renderMasters()}
                    </div>

                    <hr className="imd-border-color-140-accent" />
                    {this.renderScenes()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        email: state.login.email,
        device: state.login.device,
        view: state.view
    }
};

export const NyumbaScreenContainer = connect(mapStateToProps, {preloaderShow, preloaderHide, alert})(NyumbaScreen);
