import React from 'react';
import { connect } from "react-redux";

export class CalendarSubscribedEvent extends React.Component {
    /**
     * @returns {*}
     */
    isOpen = () => {
        return (this.props.description === null ? null : ' opened');
    };

    /**
     * @returns {XML}
     */
    render() {
        return (
            <dialog className={"imd-dialog imd-bg-transparent-gray" + this.isOpen()}>
                <div className="imd-dialog-body imd-bg-contrast">
                    <header>
                        Subskrybowane wdarzenie
                        <span className="imd-icon imd-icon-cross imd-dialog-close" onClick={() => {this.props.closeEvent();}} />
                    </header>

                    <div className="imd-dialog-content">
                        <div className="imd-row">
                            <div className="imd-col-xs-12">
                                <strong>Opis:</strong><br />
                                {this.props.description}
                            </div>
                        </div>
                    </div>
                </div>
            </dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {}
};

export const CalendarSubscribedEventContainer = connect(mapStateToProps, {})(CalendarSubscribedEvent);
