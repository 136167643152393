import React from 'react';
import axios from 'axios';
import { config } from '../../../app/config';
import { connect } from "react-redux";
import { preloaderShow, preloaderHide, alert, screenCalendarDates } from '../../../actions';

export class CalendarEvents extends React.Component {
    /**
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            events: {},
            subscribed: {},
            visibleHours: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
            showAllHours: false,
            description: null
        };
    }

    /**
     * @param prevProps
     * @param prevState
     */
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.view !== 'calendar' && this.props.view === 'calendar') {
            this.loadEvents();
            this.loadSubscribedEvents();
        } else if (this.props.selectedDate !== prevProps.selectedDate) {
            this.loadEvents()
            this.loadSubscribedEvents();
        }
    };

    /**
     * Refreshing visible hours
     */
    refreshVisibleHours = () => {
        let visibleHours = [];

        for (let x = 0; x<= 23; x++) {
            visibleHours.push(x);
        }

        this.setState({visibleHours: visibleHours});

        let hour = 15;//(new Date()).getHours();

        window.scrollTo(100 + (hour * 60), 0);

        // visibleHours = [hour];
        //
        // for (let x = 1; x <= 12 && visibleHours.length < 11; x++) {
        //     if (hour - x > 0) {
        //         visibleHours.push(hour - x);
        //     }
        //
        //     if (hour + x <= 23) {
        //         visibleHours.push(hour + x);
        //     }
        // }
        //
        // visibleHours.sort();
        //
        // this.setState({visibleHours: visibleHours});
    };

    /**
     * Change visible hours range
     */
    changeVisibleHours = () => {
        this.setState({showAllHours: !this.state.showAllHours});
        this.refreshVisibleHours();
        this.forceUpdate(); //is required to reload view and show new hours range
    };

    /**
     * Load events for week
     */
    loadEvents = () => {
        this.props.preloaderShow();
        let that = this;
        let date = this.props.selectedDate.getFullYear() + '-' +
            (this.props.selectedDate.getMonth() + 1) + '-' +
            this.props.selectedDate.getDate();

        axios.get(config.apiAddress + '/app/calendar/week/' + date)
            .then(function (response) {
                that.setState({events: response.data.results});
                that.props.preloaderHide();

                if (window.innerWidth >= 992) {
                    let currentHour = (new Date()).getHours();
                    document.getElementById('calendar_events').scrollTop = (currentHour - 2) * 60;
                }
            }).catch(function(error) {
                that.props.preloaderHide();
                that.props.alert('Nie udało się załadować kalendarza');
            });

        axios.get(config.apiAddress + '/app/calendar/dates')
            .then(function (response) {
                that.props.screenCalendarDates(response.data);
            }).catch(function(error) {
            });
    };

    /**
     * Load subscribed events for week
     */
    loadSubscribedEvents = () => {
        let that = this;
        let date = this.props.selectedDate.getFullYear() + '-' +
            (this.props.selectedDate.getMonth() + 1) + '-' +
            this.props.selectedDate.getDate();

        axios.get(config.apiAddress + '/app/calendar/subscribed/week/' + date)
            .then(function (response) {
                that.setState({subscribed: response.data});
            }).catch(function(error) {
                that.props.alert('Nie udało się załadować subskrubowanych kalendarzy');
            });
    };

    /**
     * @param dayDate
     * @returns {string}
     */
    getDayClass = (dayDate) => {
        let currentDay = dayDate ===
            (
                this.props.selectedDate.getFullYear() + '-' +
                (this.props.selectedDate.getMonth() + 1 < 10 ? '0' + (this.props.selectedDate.getMonth() + 1) : this.props.selectedDate.getMonth() + 1) + '-' +
                (this.props.selectedDate.getDate() < 10 ? '0' + this.props.selectedDate.getDate() : this.props.selectedDate.getDate())
            );

        if (currentDay) {
            return 'imd-col-cal-day imd-col-xs-12';
        }

        return 'imd-col-cal-day imd-hide-xs imd-hide-sm imd-hide-md imd-col-xs-12';
    };

    /**
     * @param event
     * @returns {{marginTop: string}|{}}
     */
    getEventStyle = (event) => {
        let convertedTime = this.convertTimestampToHour(event.time);
        convertedTime[1] = convertedTime[1] * 1;

        if (convertedTime[1] > 0) {
            return {
                height: event.length + 'px',
                marginTop: convertedTime[1] + 'px'
            };
        }

        return {height: event.length + 'px'};
    };

    /**
     * @param event
     * @param color
     * @returns {string}
     */
    getEventClass = (event, color) => {
        let defaultClass = 'event imd-shadow imd-bg-default imd-text-60-' + color + ' imd-border-color-60-' + color;

        if (event.length < 30) {
            return defaultClass + ' event-smaller';
        }

        return defaultClass;
    };

    /**
     * @param timestamp
     * @returns {[string, string]}
     */
    convertTimestampToHour = (timestamp) => {
        let date = new Date(timestamp * 1000);

        return [
            (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()),
            (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes())
        ];
    };

    displayDescription = (description) => {
        this.setState({description: description});
    }

    /**
     * @param event
     * @returns {JSX.Element}
     */
    renderEventIcons = (event) => {
        if (event.type === 'remainder') {
            return (<span className="icon-remainder imd-icon imd-icon-bell" />);
        }
    };

    renderEvents = () => {
        let events = [];
        let that = this;
        let currentDate = new Date();

        events.push(<div key="calendar-hours" className="imd-col-cal-hour">
            <div className={"empty-container"} />
            {
                that.state.visibleHours.map(hour => (
                    // eslint-disable-next-line
                    <div key={hour} className={"event-hour-container" + (hour == currentDate.getHours() || hour == currentDate.getHours() - 1 ? " imd-border-color-main" : "")}>
                        <span className="hour imd-text-accent">{hour}:00</span>
                    </div>)
                )
            }
        </div>);

        Object.keys(this.state.events).forEach(function(key) {
            if(events.length > 7) {
                return;
            }

            events.push(<div key={key} className={that.getDayClass(key)}>
                <header className="imd-hide-lg imd-hide-xl">
                    <button onClick={() => that.props.openEvent([key, null])} className="button-new-event imd-button imd-button-outline imd-button-sm imd-border-color-main imd-text-main">
                        <span className="imd-icon imd-icon-plus" />
                    </button>
                    <h1 className="imd-text-main">{key}</h1>
                </header>

                <div className={"event-daily-events-container"}>
                    {
                        /* eslint-disable */
                        that.state.subscribed[key] !== undefined ?
                            that.state.subscribed[key].map((event) => {
                                if (event.length >= 1440) {
                                    return (<div key={event.id + '_' + key} className={that.getEventClass(event, event.color)} title={event.description} onClick={(e) => {e.stopPropagation(); that.props.showSubscribedDescription(event.description)}}>
                                        {event.description}
                                    </div>)
                                }
                            })
                        : null
                        /* eslint-enable */
                    }

                    {
                        /* eslint-disable */
                        that.state.events[key].map((event) => {
                            if (event.length >= 1440) {
                                return (<div key={event.id + '_' + key} className={that.getEventClass(event, "main")} onClick={(e) => {e.stopPropagation(); that.props.openEvent(event.id)}} title={event.description}>
                                    {event.description}
                                </div>)
                            }
                        })
                        /* eslint-enable */
                    }
                </div>

                {
                    that.state.visibleHours.map(hour => (
                        // eslint-disable-next-line
                        <div key={hour} className={"event-hour-container" + (hour == currentDate.getHours() || hour == currentDate.getHours() - 1 ? " imd-border-color-main" : "")} onClick={() => that.props.openEvent([key, hour])}>
                            {
                                that.state.events[key].map((event) => {
                                    if (event.length >= 1440) {
                                        // eslint-disable-next-line
                                        return;
                                    }

                                    let convertedTime = that.convertTimestampToHour(event.time);
                                    let endTime = that.convertTimestampToHour(event.time + event.length * 60);

                                    if (convertedTime[0] * 1 !== hour) {
                                        // eslint-disable-next-line
                                        return;
                                    }

                                    return (<div key={event.id + '_' + key} className={that.getEventClass(event, "main")} onClick={(e) => {e.stopPropagation(); that.props.openEvent(event.id)}} style={that.getEventStyle(event)}  title={event.description}>
                                        {that.renderEventIcons(event)}
                                        <strong>{convertedTime[0]}:{convertedTime[1]}-{endTime[0]}:{endTime[1]}</strong>
                                        {event.description}
                                    </div>)
                                })
                            }

                            {
                                that.state.subscribed[key] !== undefined ?
                                    that.state.subscribed[key].map((event) => {
                                        if (event.length >= 1440) {
                                            // eslint-disable-next-line
                                            return;
                                        }

                                        let convertedTime = that.convertTimestampToHour(event.time);
                                        let endTime = that.convertTimestampToHour(event.time + event.length * 60);

                                        if (convertedTime[0] * 1 !== hour) {
                                            // eslint-disable-next-line
                                            return;
                                        }

                                        return (<div key={event.id + '_' + key} className={that.getEventClass(event, event.color)} style={that.getEventStyle(event)} title={event.description} onClick={(e) => {e.stopPropagation(); that.props.showSubscribedDescription(event.description)}}>
                                            <strong>{convertedTime[0]}:{convertedTime[1]}-{endTime[0]}:{endTime[1]}</strong>
                                            {event.description}
                                        </div>)
                                    })
                                : null
                            }
                        </div>)
                    )
                }
            </div>);
        });

        return events;
    };

    /**
     * @returns {XML}
     */
    render() {
        return (
            <div className="imd-container-fluid events" id="calendar_events">
                <div className="imd-row">
                    {this.renderEvents()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        email: state.login.email,
        device: state.login.device,
        view: state.view
    }
};

export const CalendarEventsContainer = connect(mapStateToProps, {preloaderShow, preloaderHide, alert, screenCalendarDates})(CalendarEvents);
