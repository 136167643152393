import React from 'react';
import Bloobs from "../../Bloobs";
import axios from 'axios';
import { connect } from "react-redux";
import { andraStateNormal, andraStateThinking, screenTalkBuffer, screenTalkContext } from '../../../actions';
import { config } from '../../../app/config';

export class TalkItem extends React.Component {
    /**
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            type: this.props.type,
            message: this.props.message,
            query: this.props.query,
            html: null,
            img: null
        };
    }

    /**
     * @returns {string}
     */
    getClassName() {
        if (this.state.type === 'query') {
            return 'imd-col-xs-11 imd-offset-xs-1 imd-text-accent screen-talk-item screen-talk-query';
        }

        return 'imd-col-xs-11 imd-text-main screen-talk-item screen-talk-answer';
    }

    /**
     * Action on create item
     */
    componentDidMount = () => {
        this.scrollToBottom();

        if (this.state.type === 'query') {
            this.setState({ message: this.state.query });
        } else {
            var that = this;
            that.props.andraStateThinking();

            let request = {
                query: that.state.query.replace(' ', '_').toLowerCase()
            };

            axios.post(config.apiAddress + '/query', request)
                .then(function (response) {
                    that.setState({message: response.data.answer});
                    that.voice(response.data.answer);
                    that.props.andraStateNormal();

                    if(response.data.instruction !== undefined) {
                        if(response.data.instruction === 'clear_buffer') {
                            that.props.screenTalkBuffer(null);
                            that.props.screenTalkContext(null);
                        }
                    }

                    if (response.data.html !== undefined) {
                        that.setState({html: response.data.html});
                    }

                    if (response.data.instruction !== undefined) {
                        if (response.data.instruction === 'img' && response.data.data !== undefined && response.data.html === undefined) {
                            that.setState({img: response.data.data});
                        }
                    }

                    if(response.data.buffer !== undefined) {
                        that.props.screenTalkBuffer(response.data.buffer);
                    }

                    if(response.data.context !== undefined) {
                        that.props.screenTalkContext(response.data.context);
                    }

                    that.scrollToBottom();
                }).catch(function(error) {
                    if (error.response.data.answer !== undefined) {
                        that.setState({message: error.response.data.answer});
                        that.voice(error.response.data.answer);
                    } else {
                        that.setState({message: 'Przepraszam, ale nie zrozumiałam.'});
                        that.voice('Przepraszam, ale nie zrozumiałam.');
                    }
                    that.props.andraStateNormal();
                });
        }
    };

    /**
     * @param message
     */
    voice = (message) => {
        if (localStorage.getItem('voice') !== 'enabled') {
            return;
        }

        axios.post(config.apiAddress + '/speech', {text: message})
            .then(function (response) {
                var audio = document.getElementById('andra-speech');
                var source = document.getElementById('andra-speech-mp3');
                source.src = config.apiAddress + '/voice/' + response.data.file;
                audio.load();
                audio.play();
            }).catch(function(error) {
            });
    };

    /**
     * Scroll page to bottom after the Andra answer.
     */
    scrollToBottom = () => {
        this.el.scrollIntoView({ behavior: 'smooth' });
    };

    /**
     * @returns {XML}
     */
    render() {
        if (this.state.type === 'query') {
            return (
                <div className={this.getClassName()}  ref={el => { this.el = el; }}>
                    {this.state.message ? this.state.message : <Bloobs />}
                </div>
            );
        }

        if (this.state.type === 'answer') {
            return (
                <div className={this.getClassName()}  ref={el => { this.el = el; }}>
                    {this.state.message ? this.state.message : <Bloobs />}
                    {this.state.html ? <div dangerouslySetInnerHTML={{ __html: this.state.html }} /> : null}
                    {this.state.img ? <img src={config.apiAddress+ '/image/' + this.state.img + '.png' } alt="Odpowiedź" /> : null}
                </div>
            );
        }

        return (<span></span>);
    }
}

const mapStateToProps = (state) => {
    return {
        email: state.login.email,
        device: state.login.device,
        buffer: state.talkScreen.buffer,
        context: state.talkScreen.context
    }
};

export const TalkItemContainer = connect(mapStateToProps, { andraStateNormal, andraStateThinking, screenTalkBuffer, screenTalkContext })(TalkItem);
