import React from 'react';
import axios from 'axios';
import { connect } from "react-redux";
import { alert } from '../../../actions';

class WeatherWidget extends React.Component {

    /**
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            initialized: false,
            apiAddress: 'https://api.icymat.pl/IcyMat/?type=weather&city=[city]&token=3293072a101516f7ab88156e72deb2f3',
            weather: {}
        };
    }

    /**
     * @param prevProps
     * @param prevState
     */
    componentDidUpdate = (prevProps, prevState) => {
        if (!this.state.initialized && this.props.user !== null) {
            this.setState({initialized: true});

            this.refreshWeather();
            let that = this;
            setInterval(function() {
                that.refreshWeather();
            }, 15 * 60000);
        }
    };

    /**
     * Refresh notifications list
     */
    refreshWeather = () => {
        if (this.props.city === null) {
            return;
        }

        let that = this;
        axios.get(this.state.apiAddress.replace('[city]', encodeURI(this.props.city)))
            .then(function (response) {
                that.setState({weather: response.data.weather});
            }).catch(function(error) {
            });
    };

    /**
     * @returns {string}
     */
    getWeatherIcon = () => {
        let icons = {
            "000": "wi-na",
            "01d": "wi-day-sunny",
            "01n": "wi-night-clear",
            "02d": "wi-day-cloudy",
            "02n": "wi-night-alt-cloudy",
            "03d": "wi-cloud",
            "03n": "wi-cloud",
            "04d": "wi-cloudy",
            "04n": "wi-cloudy",
            "09d": "wi-showers",
            "09n": "wi-showers",
            "10d": "wi-day-rain",
            "10n": "wi-night-alt-rain",
            "11d": "wi-day-lightning",
            "11n": "wi-night-alt-lightning",
            "13d": "wi-day-snow",
            "13n": "wi-night-alt-snow",
            "50d": "wi-day-fog",
            "50n": "wi-night-fog"
        };

        if (icons[this.state.weather.icon] === undefined) {
            return 'wi-na';
        }

        return icons[this.state.weather.icon];
    };

    /**
     * @returns {XML}
     */
    render() {
        return (
            <div className="widget imd-shadow-dark">
                <div className="content">
                    <div className="imd-row">
                        <div className="imd-col-12">
                            <h2 className="imd-text-main" style={{marginTop: 0}}>
                                {this.props.city}
                            </h2>
                        </div>
                        <div className="imd-col-2 imd-text-contrast" style={{fontSize: '2rem'}}>
                            <span className={"wi " + this.getWeatherIcon()} />
                        </div>
                        <div className="imd-col-3 imd-text-accent">
                            <span className="wi wi-thermometer" /><br />
                            {this.state.weather.temperature} °C
                        </div>
                        <div className="imd-col-3 imd-text-accent">
                            <span className="wi wi-strong-wind" /><br />
                            {this.state.weather.wind_speed} m/s
                        </div>
                        <div className="imd-col-4 imd-text-accent">
                            <span className="wi wi-barometer" /><br />
                            {this.state.weather.pressure} hPa
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    let city = null;
    if (state.user !== null && state.user.city !== '') {
        city = state.user.city;
    }

    return {
        city: city
    }
};

export const WeatherWidgetContainer = connect(mapStateToProps, { alert })(WeatherWidget);
