import React from 'react';
import { setView } from "../actions/index";
import { connect } from "react-redux";
import '../styles/IcyMatAssistant.scss';

class IcyMatAssistant extends React.Component {

    /**
     * Constructor
     */
    constructor(props) {
        super(props);
        this.state = {
            id: Math.floor((Math.random() * 5000) + 2000),
            activeIcon: this.props.activeIcon !== undefined && this.props.activeIcon === true
        };
    }

    openMenu = () => {
        if (this.state.activeIcon) {
            this.props.setView('menu');
        }
    };

    getMainClassName = () => {
        let className = 'icymat-assistant';

        if (this.state.activeIcon) {
            className += ' icon'
        }

        return className;
    };

    /**
     * @returns {XML}
     */
    render() {
        return (
            <div className={this.getMainClassName()} onClick={this.openMenu}>
                <div className="scene" id={"scene" + this.state.id}>
                    <div className="shape cuboid-1 center">
                        <div className="face ft">
                            <div className="photon-shader" />
                        </div>
                        <div className="face bk">
                            <div className="photon-shader" />
                        </div>
                        <div className="face rt">
                            <div className="photon-shader" />
                        </div>
                        <div className="face lt">
                            <div className="photon-shader" />
                        </div>
                        <div className="face bm">
                            <div className="photon-shader" />
                        </div>
                        <div className="face tp">
                            <div className="photon-shader" />
                        </div>
                    </div>
                    <div className="shape cuboid-2 big-top">
                        <div className="face ft">
                            <div className="photon-shader" />
                        </div>
                        <div className="face bk">
                            <div className="photon-shader" />
                        </div>
                        <div className="face rt">
                            <div className="photon-shader" />
                        </div>
                        <div className="face lt">
                            <div className="photon-shader" />
                        </div>
                        <div className="face bm">
                            <div className="photon-shader" />
                        </div>
                        <div className="face tp">
                            <div className="photon-shader" />
                        </div>
                    </div>
                    <div className="shape cuboid-3 big-bottom">
                        <div className="face ft">
                            <div className="photon-shader" />
                        </div>
                        <div className="face bk">
                            <div className="photon-shader" />
                        </div>
                        <div className="face rt">
                            <div className="photon-shader" />
                        </div>
                        <div className="face lt">
                            <div className="photon-shader" />
                        </div>
                        <div className="face bm">
                            <div className="photon-shader" />
                        </div>
                        <div className="face tp">
                            <div className="photon-shader" />
                        </div>
                    </div>
                    <div className="shape cuboid-4 medium-top">
                        <div className="face ft">
                            <div className="photon-shader" />
                        </div>
                        <div className="face bk">
                            <div className="photon-shader" />
                        </div>
                        <div className="face rt">
                            <div className="photon-shader" />
                        </div>
                        <div className="face lt">
                            <div className="photon-shader" />
                        </div>
                        <div className="face bm">
                            <div className="photon-shader" />
                        </div>
                        <div className="face tp">
                            <div className="photon-shader" />
                        </div>
                    </div>
                    <div className="shape cuboid-5 medium-bottom">
                        <div className="face ft">
                            <div className="photon-shader" />
                        </div>
                        <div className="face bk">
                            <div className="photon-shader" />
                        </div>
                        <div className="face rt">
                            <div className="photon-shader" />
                        </div>
                        <div className="face lt">
                            <div className="photon-shader" />
                        </div>
                        <div className="face bm">
                            <div className="photon-shader" />
                        </div>
                        <div className="face tp">
                            <div className="photon-shader" />
                        </div>
                    </div>
                    <div className="shape cuboid-6 small-top">
                        <div className="face ft">
                            <div className="photon-shader" />
                        </div>
                        <div className="face bk">
                            <div className="photon-shader" />
                        </div>
                        <div className="face rt">
                            <div className="photon-shader" />
                        </div>
                        <div className="face lt">
                            <div className="photon-shader" />
                        </div>
                        <div className="face bm">
                            <div className="photon-shader" />
                        </div>
                        <div className="face tp">
                            <div className="photon-shader" />
                        </div>
                    </div>
                    <div className="shape cuboid-7 small-bottom">
                        <div className="face ft">
                            <div className="photon-shader" />
                        </div>
                        <div className="face bk">
                            <div className="photon-shader" />
                        </div>
                        <div className="face rt">
                            <div className="photon-shader" />
                        </div>
                        <div className="face lt">
                            <div className="photon-shader" />
                        </div>
                        <div className="face bm">
                            <div className="photon-shader" />
                        </div>
                        <div className="face tp">
                            <div className="photon-shader" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    componentDidMount() {
        let scene = document.getElementById('scene' + this.state.id);
        let rotateY = 0;

        var that = this;
        setInterval(function() {
            scene.style.transform = 'rotateX(-15deg) rotateY(' + rotateY + 'deg)';

            if (that.props.andra === 2) {
                rotateY += 2;
            }

            if (rotateY++ >= 360) {
                rotateY = 0;
            }
        }, 15);
    }
}

const mapStateToProps = (state) => {
    return {
        andra: state.andra
    }
};

export const IcyMatAssistantContainer = connect(mapStateToProps, { setView })(IcyMatAssistant);