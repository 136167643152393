import React from 'react';
import axios from 'axios';
import { config } from '../../../app/config';
import { connect } from "react-redux";
import { alert } from '../../../actions';

class NotificationsWidget extends React.Component {

    /**
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            initialized: false,
            notifications: {
                new: [],
                unread: []
            },
            notification: {
                title: '',
                content: ''
            }
        };

        Notification.requestPermission(function (permission) {
        });
    }

    /**
     * @param prevProps
     * @param prevState
     */
    componentDidMount = (prevProps, prevState) => {
        if (!this.state.initialized) {
            this.setState({initialized: true});

            this.refreshNotifications();
            let that = this;
            setInterval(function() {
                that.refreshNotifications();
            }, 60000);
        }
    };

    /**
     * @param prevProps
     * @param prevState
     * @param snapshot
     */
    componentDidUpdate = (prevProps, prevState, snapshot) => {
        if (prevProps.email !== this.props.email) {
            let that = this;
            setTimeout(function() {
                that.refreshNotifications();
            }, 500);
        }
    }

    /**
     * Refresh notifications list
     */
    refreshNotifications = () => {
        if (this.props.email === null) {
            return;
        }
        let that = this;

        axios.get(config.apiAddress + '/notifications')
            .then(function (response) {
                that.setState({notifications: response.data});

                if (response.data.new.length > 0) {
                    that.props.alert('Pojawiły się nowe powiadomienia');
                    new Notification('Andra', {body: 'Pojawiły się nowe powiadomienia', icon: './images/icons/48.png'});
                }
            }).catch(function(error) {
            });
    };

    /**
     * @param notificationId
     */
    setAsRead = (notificationId) => {
        let that = this;
        axios.patch(config.apiAddress + '/notifications/set-as-read/' + notificationId)
            .then(function (response) {
                if (response.data.status === 'success') {
                    that.refreshNotifications();
                } else {
                    this.props.alert('Nie udało się usunąć powiadomienia');
                }
            }).catch(function(error) {
                this.props.alert('Nie udało się usunąć powiadomienia');
            });
    };

    /**
     * @returns {XML}
     */
    render() {
        let counter = 0;
        return (
            <div className="widget widget-big imd-shadow-dark">
                <div className="content">
                    {this.state.notifications.new.map((notification, i) => {
                        counter++;

                        return (<div className="imd-notification imd-bg-20-main imd-text-main" key={i}>
                            <button type="button" className="imd-notification-close imd-text-main" onClick={() => this.setAsRead(notification.id)}>
                                <span aria-hidden="true">×</span>
                            </button>
                            {notification.message}
                        </div>);
                    })}

                    {this.state.notifications.unread.map((notification, i) => {
                        counter++;

                        return (<div className="imd-notification imd-bg-20-default imd-text-default" key={i}>
                            <button type="button" className="imd-notification-close imd-text-default" onClick={() => this.setAsRead(notification.id)}>
                                <span aria-hidden="true">×</span>
                            </button>
                            {notification.message}
                        </div>);
                    })}

                    {(counter === 0 ? <span className="imd-text-accent">Brak powiadomień</span>: '')}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        email: state.login.email,
        device: state.login.device
    }
};

export const NotificationsWidgetContainer = connect(mapStateToProps, { alert })(NotificationsWidget);

