import React from 'react';
import { AndraContainer } from '../Andra';
import { IcyMatAssistantContainer } from '../IcyMatAssistant';
import { LicenseComponent } from "../screens/settings/LicenseComponent";
import axios from 'axios';
import Fingerprint2 from 'fingerprintjs2';
import { config } from '../../app/config';
import { updateFingerprint, login, userRefresh, preloaderShow, preloaderHide, setView, alert, splashScreenHide } from "../../actions";
import { connect } from "react-redux";
import '../../styles/login.scss';

export class Login extends React.Component {

    /**
     * @param props
     */
    constructor(props)
    {
        super(props);

        this.state = {
            license: false
        };
    };

    componentDidMount() {
        let that = this;
        setTimeout(function() {
            let fingerprint = localStorage.getItem('fingerprint');
            if (fingerprint !== null) {
                //it's old fingerprint so we have to recalculate it and remove user from the localstorage
                if (fingerprint.length === 40) {
                    localStorage.removeItem('fingerprint');
                    localStorage.removeItem('email');
                    localStorage.removeItem('device');

                    that.props.login({
                        email: null,
                        device: null
                    });

                    that.props.userRefresh({
                        email: null,
                        imid: [],
                        name: null
                    });

                    that.props.setView(null);
                    that.props.alert('Wymagane ponowne logowanie');
                }
            }

            if (localStorage.getItem('fingerprint') === null) {
                Fingerprint2.get(function (components) {
                    var values = components.map(function (component) { return component.value });
                    localStorage.setItem('fingerprint', Fingerprint2.x64hash128(values.join(''), 64));
                })
            }

            if (that.props.email !== null && that.props.device !== null) {
                that.refreshUser(that.props.email, that.props.device);
            } else {
                that.props.splashScreenHide();
            }
        }, 1000);
    }

    initializeLogin = (e) => {
        let that = this;
        this.props.preloaderShow();

        window.open(config.imidServer + 'oauth/app/' + config.imidOAuthId, "IMid OAuth", "width=550,height=500,resizable=no");
        window.addEventListener('message', function(event) {
            let response = JSON.parse(event.data);

            if (response.token === undefined) {
                that.props.alert('Nie udało się zalogować');
                that.props.preloaderHide();

                return;
            }

            let data = {
                token: response.token,
                fingerprint: localStorage.getItem('fingerprint')
            };

            axios.post(config.apiAddress + '/login', data)
                .then(function (response) {
                    localStorage.setItem('email', response.data.user);
                    localStorage.setItem('device', response.data.device);

                    that.props.login({
                        email: localStorage.getItem('email'),
                        device: localStorage.getItem('device')
                    });

                    that.refreshUser(response.data.user, response.data.device);
                    that.props.setView('main');
                }).catch(function(response) {
                    that.props.alert('Nie udało się zalogować');
                    that.props.preloaderHide();
                });
        });
    };

    getStyle = (e) => {
        return {
            display: this.props.email === null ? 'block' : 'none'
        }
    };

    refreshUser = (email, device) => {
        this.props.preloaderShow();

        axios.defaults.headers.common = {
            'X-AUTH-USER': email,
            'X-AUTH-DEVICE': device,
            'X-AUTH-FINGERPRINT': localStorage.getItem('fingerprint')
        };

        let that = this;
        axios.get(config.apiAddress + '/user')
            .then(function (response) {
                that.props.userRefresh(response.data);
                that.props.preloaderHide();
                that.props.splashScreenHide();
            }).catch(function(response) {
                that.props.preloaderHide();
                that.props.splashScreenHide();
            });
    };

    /**
     * @returns {*}
     */
    renderLogo = () => {
        if (config.icymat_assistant) {
            return (
                <IcyMatAssistantContainer />
            );
        }

        return (
            <AndraContainer />
        );
    };

    render() {
        /* eslint-disable */
        return (
            <div id="login" style={this.getStyle()}>
                <div className={"logo"}>
                    {this.renderLogo()}
                </div>

                <button className="imd-button imd-button-outline imd-border-color-imid imd-text-imid" onClick={this.initializeLogin}>
                    <img src={'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAD7klEQVRo3u3Ya4hUZRwG8J+7q2m2tmUXUmfbgjzdkdSKNLqHyQZ2LyG7R0QhBJUExdtV+mIFRgTRhSKiIMQMDKGs8JNClEjMp6ATeSGzJC1Nd/vwnpFtnZk9s2cWIs4D82HOnPN/z/M+/8vzDiVKlChRokSJ/w/GFQ2QhHQm9mB7NVQO1Pl9Bk7Bxmqo/DVWRDoKkujGEtyF45OQ1rvtDLyAB5OQnpWEtNCajdBVgATMwzWYis1Yi311Nms+zsFFWJ2EdB22VkOlbUSK7M5JuB4JTsZiTGugChyNRQh4CguSkE5uF5FRKZKlx1W4FLWXuRqfYYdYM/XQKdbLnZiNtUlIV+HbaqgcLEJktIokuBZ9Q6714B70JiEdqYlMxBw8hOfxQBLS3iZqtp9IEtKJYl3Mx/hhP88R0607R6hxOAZX4jEsx6IkpFPGnEi2Y+dhIU6oc8sE3I0kCWlnzrBd6MV1eA5PJyGdm4R0fM7nWyeS7eBCnN/k2T7cL58qQzEJZ4qt/BUsbSXdcg/ELOBCMadnjXD7b2IXW4crxLbcCg7iF2zCW1mc3c3adSuK9KI/27WR0INHta5KDZ04UeyMK7LP7GbplkuRLN9vwYuYkfNl9otd6WesGSWhGvbiR7yHN7GtGiqDQ2/Iq8jpYjFOa2HxCViG4wqSgCPFlv84PsYNSUgnDa2fEYkkIZ2ES7CgBeI19OHhNhAhZk835oqqvIOza94tz4udi9tw1CgW7xDrZWebyBDrp1ucV+txcRLSjoYWJZNtqjj85hVYeBdeFltqO5zvIA7gOzyJDQwONPNaHeLwu13xc8vbuEOc/EWwH1vxKt7ArlpLrkskU2MGbsSpbdjFvaLr/UgcfK3ib1HZ1XgJ1eEms5EiR4jT++Y2kIABfIlVuFV+hQ/id2zESnxeDZW99W48LGcz51qz2j3tYLHz65VEVVZge45HBrEb3+DZ7F3WNCLBMEWylJqMy8XzRdtQDZWBJKRVMbeXaZwNf4rDb63YZrfkOasMD9aJmbjX4Ra9HfhDnM79DvdrB7ANG/Au1ldDZU/ewIdSK1Njiljgs/IGaAWZrfgJr2WkiPXzqzgTluMRfNoKCf6tSFdGYIk2/E3UBHvEI3G/6Iy/z75/iM3VUBkYTdAuDqlxLO7D9DEkUcMufCJ2pdfxVbNCzk1ErIfLxHP4mCHzRdNEJeZjC76ohsq+QoExLmu3FbyvmBVphE2i0esWzWe/eECbLrbXpdVQ2VB0kQ6xU92EC8eARG2NC8Q/GJ4R07eSXT8Ni5OQ9hRdpEscPj/giTEisl20GDvwQfapYVCcGYOjiFuiRIkSJUqUKPFfxz/Muv9G1RWmdAAAAABJRU5ErkJggg=='}
                         alt="IMid"
                         style={{height: '30px', marginRight: '5px', marginBottom: '-8px'}} />
                    Zaloguj się z IMid
                </button>

                <div className="license">
                    <button className="imd-text-accent" onClick={() => {this.setState({license: true})}}>
                        Licencja
                    </button>
                </div>

                <div className="madeby-mateusz-kolasa">
                    made by
                    <a href="https://mateuszkolasa.com" target="_blank">
                        <img src="images/logo-mateuszkolasa.svg" alt="Mateusz Kolasa" />
                    </a>
                </div>

                <dialog className={(this.state.license ? 'imd-dialog imd-bg-transparent-gray opened' : 'imd-dialog imd-bg-transparent-gray')}>
                    <div className="imd-dialog-body imd-bg-white">
                        <header>
                            Licencja
                            <span className="imd-icon imd-icon-cross imd-dialog-close" onClick={() => {this.setState({license: false});}} />
                        </header>

                        <div className="imd-dialog-content">
                            <LicenseComponent textClass="imd-text-accent" />
                        </div>
                    </div>
                </dialog>
            </div>
        );
        /* eslint-enable */
    }
}

const mapStateToProps = (state) => {
    return {
        email: state.login.email,
        device: state.login.device,
        fingerprint: state.login.fingerprint
    }
};

const mapDispatchToProps = { updateFingerprint, login, userRefresh, preloaderShow, preloaderHide, setView, alert, splashScreenHide };

export const LoginContainer = connect(mapStateToProps, mapDispatchToProps)(Login);
