import React from 'react';
import axios from "axios";
import { config } from "../app/config";
import { SplashScreenContainer } from "./SplashScreenContainer";
import { LoginContainer } from './security/LoginContainer';
import { PreloaderContainer } from './Preloader';
import { AlertContainer } from "./Alert";
import { MainPanelContainer } from './MainPanel';
import { MainScreenContainer } from './screens/MainScreenContainer';
import { MenuScreenContainer } from './screens/MenuScreenContainer';
import { TalkScreenContainer } from './screens/TalkScreenContainer';
import { NoteScreenContainer } from './screens/NoteScreenContainer';
import { CalendarScreenContainer } from './screens/CalendarScreenContainer';
import { FinanceScreenContainer } from "./screens/FinanceScreenContainer";
import { FitScreenContainer } from './screens/FitScreenContainer';
import { RecipesScreenContainer } from "./screens/RecipesScreenContainer";
import { ToDoScreenContainer } from "./screens/ToDoScreenContainer";
import { NyumbaScreenContainer } from './screens/NyumbaScreenContainer';
import { SettingsScreenContainer } from './screens/SettingsScreenContainer';
import { connect } from "react-redux";
import Background from './backgrounds/default';
import '../styles/App.css';
import '../styles/icymat-design/dark.scss';
import '../styles/icymat-design/icymat-assistant.scss';

export class App extends React.Component {
    constructor(props){
        super(props);

        if (config.icymat_assistant) {
            this.state = {style: 'icymat-assistant'};
        } else {
            this.state = {style: 'dark'};
        }

        if (localStorage.getItem('email') !== null) {
            axios.defaults.headers.common = {
                'X-AUTH-USER': localStorage.getItem('email'),
                'X-AUTH-DEVICE': localStorage.getItem('device'),
                'X-AUTH-FINGERPRINT': localStorage.getItem('fingerprint')
            };
        }
    }

    render() {
        return (
            <div className={"style-" + this.state.style}>
                <div>
                    <SplashScreenContainer />
                    <LoginContainer />
                    <PreloaderContainer />
                    <Background />
                    <AlertContainer />
                    <MainPanelContainer />
                </div>

                <div id="screens">
                    <MainScreenContainer />
                    <MenuScreenContainer />
                    <TalkScreenContainer />
                    <NoteScreenContainer />
                    <CalendarScreenContainer />
                    <FinanceScreenContainer />
                    <FitScreenContainer />
                    <RecipesScreenContainer />
                    <ToDoScreenContainer />
                    <NyumbaScreenContainer />
                    <SettingsScreenContainer />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        fingerprint: state.fingerprint
    }
};

const mapDispatchToProps = { };

export const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);
