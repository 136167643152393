import React from 'react';
import { connect } from "react-redux";

export class CalendarNavigation extends React.Component {
    /**
     * Monday = 1, Sunday = 7
     *
     * @param date
     * @returns {number}
     */
    getDayNumber = (date) => {
        if (date.getDay() === 0) {
            return 7;
        }

        return date.getDay();
    };

    /**
     * @param date
     * @returns {*}
     */
    getDayStyle = (date) => {
        let full = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();

        let currentStyle = 'normal';
        let today = new Date();
        today= today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        if (today === full) {
            currentStyle = 'bold';
        }


        if (this.props.dates.indexOf('daily') > -1) {
            return {
                textDecoration: 'underline',
                fontWeight: currentStyle
            };
        }

        if (this.props.dates.indexOf('weekly_' + (date.getDay() + 1)) > -1) {
            return {
                textDecoration: 'underline',
                fontWeight: currentStyle
            };
        }

        if (this.props.dates.indexOf('monthly_' + date.getDate()) > -1) {
            return {
                textDecoration: 'underline',
                fontWeight: currentStyle
            };
        }

        if (this.props.dates.indexOf('yearly_' + (date.getMonth() + 1) + '-' + date.getDate()) > -1) {
            return {
                textDecoration: 'underline',
                fontWeight: currentStyle
            };
        }

        if (this.props.dates.indexOf(full) > -1) {
            return {
                textDecoration: 'underline',
                fontWeight: currentStyle
            };
        }

        return {
            fontWeight: currentStyle
        };
    };

    createDays() {
        let rows = [];

        for (let i = this.getDayNumber(this.props.selectedDate); i > 1; i--) {
            let dayDate = new Date(this.props.selectedDate.getTime() - 86400000 * (i - 1));
            rows.push(<button className="day imd-text-accent" style={this.getDayStyle(dayDate)} key={dayDate.getDate()} onClick={() => this.props.changeDate(dayDate)}>{dayDate.getDate()}</button>);
        }

        rows.push(<button className="day current imd-border-color-accent imd-text-accent" key={this.props.selectedDate.getDate()}>{this.props.selectedDate.getDate()}</button>);

        let diff = 1;
        for (let i = this.getDayNumber(this.props.selectedDate); i < 7; i++, diff++) {
            let dayDate = new Date(this.props.selectedDate.getTime() + 86400000 * diff);
            rows.push(<button className="day imd-text-accent" style={this.getDayStyle(dayDate)} key={dayDate.getDate()} onClick={() => this.props.changeDate(dayDate)}>{dayDate.getDate()}</button>);
        }

        return (rows);
    };

    /**
     * @param date
     * @returns {string}
     */
    formatFullDate = (date) => {
        return  (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '.' +
            (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '.' +
            date.getFullYear();
    };

    createDayHeaders = () => {
        let rows = [];

        rows.push(<div className="imd-col-cal-hour imd-text-accent" key="hour" />);

        let today = this.formatFullDate(new Date());
        let firstDay = new Date(this.props.selectedDate.getTime() - 86400000 * (this.getDayNumber(this.props.selectedDate) - 1));
        for (let i = 0; i < 7; i++) {
            let dayDate = new Date(firstDay.getTime() + 86400000 * i);
            let fullDate = this.formatFullDate(dayDate);
            if (today === fullDate) {
                rows.push(<div className="imd-col-cal-day imd-text-main" key={dayDate.getDate()}>{fullDate}</div>);
            } else if (this.getDayNumber(dayDate) === 6) {
                rows.push(<div className="imd-col-cal-day imd-text-40-blue" key={dayDate.getDate()}>{fullDate}</div>);
            } else if (this.getDayNumber(dayDate) === 7) {
                rows.push(<div className="imd-col-cal-day imd-text-40-red" key={dayDate.getDate()}>{fullDate}</div>);
            } else {
                rows.push(<div className="imd-col-cal-day imd-text-accent" key={dayDate.getDate()}>{fullDate}</div>);
            }
        }

        return (rows);
    };

    getTodayButtonClasses = () => {
        let classes = "day-today imd-button imd-button-outline imd-button-sm imd-border-color-accent imd-text-accent";

        let now = new Date();
        let selected = new Date(this.props.selectedDate.getTime());

        if (this.formatFullDate(now) !== this.formatFullDate(selected)) {
            return classes;
        }

        return classes + " imd-hide-xs imd-hide-sm imd-hide-md imd-hide-lg imd-hide-xl";
    }

    /**
     * @returns {XML}
     */
    render() {
        return (
            <div className="days">
                {this.createDays()}

                <button className={this.getTodayButtonClasses()} onClick={() => this.props.changeDate(new Date())}>
                    Dziś
                </button>

                <button className="day-calendar imd-button imd-button-outline imd-button-sm imd-border-color-main imd-text-main" onClick={() => this.props.showCalendar()}>
                    <span className="imd-icon imd-icon-calendar" />
                </button>

                <div className="imd-container-fluid imd-hide-xs imd-hide-sm imd-hide-md" style={{paddingRight: '26px'}}>
                    <div className="imd-row">
                        {this.createDayHeaders()}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        dates: state.calendarScreen.dates
    }
};

export const CalendarNavigationContainer = connect(mapStateToProps, {})(CalendarNavigation);
