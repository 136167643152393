import React from 'react';
import { connect } from "react-redux";
import { alert } from '../../../actions';

class ImportantInformationWidget extends React.Component {

    /**
     * @returns {XML}
     */
    render() {
        return (
            <div className="widget imd-shadow-dark">
                <div className="content">
                    <div className="imd-row">
                        <div className="imd-col-12">
                            <h2 className="imd-text-main" style={{marginTop: 0}}>
                                Informacja
                            </h2>

                            <p className="imd-text-accent">
                                W związku z narastającą dezinformacją i falą fakenewsów pojawiających się w związku z postępującą pandemią wirusa SARS-Cov-19 przypominam, że aktualne i rzetelne informacje przekazywane są przez organy władzy państwowej.<br />
                                Wszelkie informacje znajdują się na stronie <a href="https://gov.pl/koronawirus">gov.pl/koronawirus</a> oraz na oficjanych profilach Inspekcji Sanitarnej, Premiera oraz odpowiednich ministerstw w mediach społecznościowych.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
    }
};

export const ImportantInformationWidgetContainer = connect(mapStateToProps, { alert })(ImportantInformationWidget);
