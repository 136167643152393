let email = localStorage.getItem('email');
let device = localStorage.getItem('device');
const initialState = email && device ? { email: email, device: device } : { email: null, device: null };

export const login = (state = initialState, action) => {
    switch (action.type) {
        case 'LOGIN':
            return {
                email: action.user.email,
                device: action.user.device
            };
        default:
            return state
    }
};
