import React from 'react';
import { alert } from '../actions';
import { connect } from "react-redux";
import '../styles/elements/preloader.scss';

export class Alert extends React.Component {
    /**
     * Constructor
     */
    constructor(props)
    {
        super(props);
        this.state = {
            className: ''
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({className: 'imd-alert'});

        let that = this;
        setTimeout(function() {
            that.setState({className: 'imd-alert closed'});
        }, 3000);
    }

    /**
     * @returns {XML}
     */
    render() {
        return (
            <div className={this.state.className}>
                <div className="imd-alert-container imd-bg-transparent-gray imd-text-white">
                    {this.props.message}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        message: state.alert
    }
};

export const AlertContainer = connect(mapStateToProps, { alert })(Alert);
