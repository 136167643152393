import React from 'react';
import ScreenComponent from './ScreenComponent';
import { connect } from "react-redux";
import { CalendarNavigationContainer } from "./calendar/CalendarNavigationContainer";
import { CalendarMonthContainer } from "./calendar/CalendarMonthContainer";
import { CalendarEventContainer } from "./calendar/CalendarEventContainer";
import { CalendarEventsContainer } from "./calendar/CalendarEventsContainer";
import '../../styles/elements/screen.scss';
import '../../styles/elements/screens/calendar.scss';
import '../../styles/elements/app.scss';
import {CalendarSubscribedEventContainer} from "./calendar/CalendarSubscribedEventContainer";

export class CalendarScreen extends ScreenComponent
{
    /**
     * @param props
     */
    constructor(props)
    {
        super(props);

        this.state = {
            date: new Date(),
            selectedDate: new Date(),
            showMonth: false,
            eventId: null,
            subscribedEventDescription: null
        };
    }

    /**
     * @returns {string}
     */
    getScreenName() {
        return 'calendar';
    }

    setSelectedDate = (date) => {
        this.setState({
            selectedDate: date,
            showMonth: false
        });
    }

    showMonth = () => {
        this.setState({showMonth: true});
    };

    hideMonth = () => {
        this.setState({showMonth: false});
    };

    /**
     * @param eventId
     */
    openEvent = (eventId) => {
        this.setState({eventId: eventId});
    };

    /**
     * Close event window
     */
    closeEvent = () => {
        this.setState({
            eventId: null,
            //selectedDate: new Date()
        });
    };

    showSubscribedDescription = (description) => {
        this.setState({subscribedEventDescription: description})
    }

    /**
     * @returns {XML}
     */
    render() {
        return (
            <div className={this.getClass() + ' app imd-bg-transparent-default'} style={this.getStyle()}>
                <div className="title imd-bg-transparent-accent imd-text-default">
                    Kalendarz
                </div>

                <div className="content imd-text-accent">
                    <div className="actions">
                        <CalendarNavigationContainer selectedDate={this.state.selectedDate} changeDate={this.setSelectedDate} showCalendar={this.showMonth} />
                    </div>

                    <hr className="imd-border-color-140-accent" style={{marginBottom: 0}} />

                    <CalendarMonthContainer selectedDate={this.state.selectedDate} changeDate={this.setSelectedDate} hideMonth={this.hideMonth} show={this.state.showMonth} />

                    <CalendarEventsContainer selectedDate={this.state.selectedDate} openEvent={this.openEvent} showSubscribedDescription={this.showSubscribedDescription} />

                    <CalendarEventContainer eventId={this.state.eventId} closeEvent={this.closeEvent} changeDate={this.setSelectedDate} selectedDate={this.state.selectedDate} />

                    <CalendarSubscribedEventContainer description={this.state.subscribedEventDescription} closeEvent={() => this.setState({subscribedEventDescription: null})} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        email: state.login.email,
        device: state.login.device,
        view: state.view
    }
};

export const CalendarScreenContainer = connect(mapStateToProps, {})(CalendarScreen);
