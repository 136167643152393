import React from 'react';
import ScreenComponent from './ScreenComponent';
import { RecipesList } from './recipes/RecipesList';
import { RecipePreview } from "./recipes/RecipePreview";
import { RecipeEdit } from "./recipes/RecipeEdit";
import { connect } from "react-redux";
import { preloaderShow, preloaderHide, alert } from "../../actions";
import { config } from "../../app/config";
import axios from "axios";
import '../../styles/elements/screen.scss';
import '../../styles/elements/screens/recipes.scss';
import '../../styles/elements/app.scss';

export class RecipesScreen extends ScreenComponent {

    /**
     * @param props
     */
    constructor(props)
    {
        super(props);

        this.state = {
            data: [],
            subscreen: 'list',
            recipe: {name: '', public: false, ingredients: '', recipe: ''}
        };
    };

    /**
     * @param prevProps
     * @param prevState
     */
    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.view !== this.getScreenName() && this.props.view === this.getScreenName()) {
            this.loadData();
        }
    };

    /**
     * @returns {string}
     */
    getScreenName() {
        return 'recipes';
    }

    /**
     * Load data
     */
    loadData = () => {
        this.props.preloaderShow();
        let that = this;

        that.setState({data: []});
        axios.get(config.apiAddress + '/app/recipe/list')
            .then(function (response) {
                that.setState({data: response.data});
                that.props.preloaderHide();
            }).catch(function(error) {
                that.props.alert('Nie udało się załadować danych');
                that.props.preloaderHide();
            });
    };

    /**
     * Save device
     */
    saveRecipe = (data) => {
        this.props.preloaderShow();
        let that = this;

        console.log(this.state);
        console.log(data);

        if (data.id !== undefined) {
            axios.put(config.apiAddress + '/app/recipe/' + data.id, data)
                .then(function (response) {
                    that.setState({subscreen: 'list', recipe: {}});
                    that.props.alert('Przepis został zapisany');
                    that.loadData();
                }).catch(function(error) {
                    that.props.alert('Nie udało się zapisać przepisu');
                    that.props.preloaderHide();
                });

            return;
        }

        axios.post(config.apiAddress + '/app/recipe', data)
            .then(function (response) {
                that.setState({subscreen: 'list', recipe: {}});
                that.props.alert('Przepis został zapisany');
                that.loadData();
            }).catch(function(error) {
                that.props.alert('Nie udało się zapisać przepisu');
                that.props.preloaderHide();
            });
    };

    /**
     * @param id
     */
    openRecipe = (id) => {
        this.props.preloaderShow();
        let that = this;

        axios.get(config.apiAddress + '/app/recipe/' + id)
            .then(function (response) {
                that.setState({
                    subscreen: 'preview',
                    recipe: response.data
                });
                that.props.preloaderHide();
            }).catch(function(error) {
                that.props.alert('Nie udało się otworzyć przepisu');
                that.props.preloaderHide();
            });
    };

    /**
     * @param name
     * @param clearRecipe
     */
    openSubscreen = (name, clearRecipe) => {
        this.setState({subscreen: name})

        if (typeof clearRecipe !== 'undefined') {
            this.setState({recipe: {name: '', public: false, ingredients: '', recipe: ''}})
        }
    };

    /**
     * @returns {XML}
     */
    render() {
        return (
            <div className={this.getClass() + ' app imd-bg-transparent-default'} style={this.getStyle()}>
                <div className="title imd-bg-transparent-accent imd-text-default">
                    Przepisy
                </div>

                <div className="content">
                    <RecipesList
                        subscreen={this.state.subscreen}
                        recipes={this.state.data}
                        openSubscreen={this.openSubscreen}
                        openRecipe={this.openRecipe}
                    />

                    <RecipePreview
                        subscreen={this.state.subscreen}
                        recipe={this.state.recipe}
                        openSubscreen={this.openSubscreen}
                    />

                    <RecipeEdit
                        subscreen={this.state.subscreen}
                        recipe={this.state.recipe}
                        openSubscreen={this.openSubscreen}
                        saveRecipe={this.saveRecipe}
                        reloadData={this.loadData}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        email: state.login.email,
        device: state.login.device,
        user: state.user,
        view: state.view
    }
};
const mapDispatchToProps = { preloaderShow, preloaderHide, alert };

export const RecipesScreenContainer = connect(mapStateToProps, mapDispatchToProps)(RecipesScreen);
