import React from 'react';
import { connect } from "react-redux";
import { TalkItemContainer } from "./talk/TalkItemContainer";
import ScreenComponent from './ScreenComponent';
import '../../styles/elements/screen.scss';
import '../../styles/elements/screens/talk.scss';

export class TalkScreen extends ScreenComponent {
    /**
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            talks: []
        };
    }

    /**
     * @returns {string}
     */
    getScreenName() {
        return 'talk';
    }

    /**
     * @param prevProps
     * @param prevState
     */
    componentDidUpdate = (prevProps, prevState) => {
        if (this.props.query !== prevProps.query) {
            var talks = this.state.talks;
            talks.push({
                type: 'query',
                query: this.props.query
            });

            talks.push({
                type: 'answer',
                query: this.props.query
            });

            this.setState({ talks: talks });
        }
    }

    /**
     * @returns {XML}
     */
    render() {
        return (
            <div className={this.getClass()} style={this.getStyle()}>
                <div className="imd-container">
                    <div className="imd-row">
                        {this.state.talks.map((item, x) => <TalkItemContainer key={x} type={item.type} query={item.query} />)}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        view: state.view,
        query: state.talkScreen.query
    }
};

export const TalkScreenContainer = connect(mapStateToProps, {})(TalkScreen);
