import React from 'react';
import ScreenComponent from './ScreenComponent';
import { months } from '../LanguageComponent';
import { connect } from "react-redux";
import { preloaderShow, preloaderHide, alert, login, userRefresh, setView } from "../../actions";
import { config } from "../../app/config";
import axios from "axios";
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis } from 'recharts';
import '../../styles/elements/screen.scss';
import '../../styles/elements/screens/fit.scss';
import '../../styles/elements/app.scss';

export class FitScreen extends ScreenComponent {

    /**
     * @param props
     */
    constructor(props)
    {
        super(props);

        let date = new Date();

        this.state = {
            data: [],
            month: date.getMonth() + 1,
            year: date.getFullYear()
        };
    };

    /**
     * @param prevProps
     * @param prevState
     */
    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.view !== this.getScreenName() && this.props.view === this.getScreenName()) {
            this.loadData();
        }
    };

    /**
     * @returns {string}
     */
    getScreenName() {
        return 'fit';
    }

    /**
     * Load data
     */
    loadData = (month, year) => {
        if (month === undefined) {
            month = this.state.month;
        }

        if (year === undefined) {
            year = this.state.year;
        }

        this.props.preloaderShow();
        let that = this;

        that.setState({data: []});
        axios.get(config.apiAddress + '/app/fit/' + year + '/' + month)
            .then(function (response) {
                that.setState({data: response.data});
                that.props.preloaderHide();
            }).catch(function(error) {
                that.props.alert('Nie udało się załadować danych');
                that.props.preloaderHide();
            });
    };

    /**
     * @param event
     */
    handleValueChange = (event) => {
        let data = this.state.data;

        data[event.target.dataset.key][event.target.dataset.properties] = event.target.value;

        this.setState({data: data});
    };

    /**
     * @returns {[]}
     */
    renderData = () => {
        let rows = [];

        let that = this;
        Object.keys(this.state.data).forEach(function(key) {
            rows.push(<tr key={key}>
                <td>{that.state.data[key].date}</td>
                <td>
                    <input className="imd-form-control imd-border-color-main imd-text-main" placeholder="[kg]" value={that.state.data[key].weight_1} data-key={key} data-properties="weight_1" onChange={that.handleValueChange} onBlur={that.saveData} />
                </td>
                <td>
                    <input className="imd-form-control imd-border-color-main imd-text-main" placeholder="[kg]" value={that.state.data[key].weight_2} data-key={key} data-properties="weight_2" onChange={that.handleValueChange} onBlur={that.saveData} />
                </td>
                <td>
                    <input className="imd-form-control imd-border-color-main imd-text-main" placeholder="XXX/XXX" value={that.state.data[key].pressure_morning} data-key={key} data-properties="pressure_morning" onChange={that.handleValueChange} onBlur={that.saveData} />
                </td>
                <td>
                    <input className="imd-form-control imd-border-color-main imd-text-main" placeholder="XXX/XXX" value={that.state.data[key].pressure_evening} data-key={key} data-properties="pressure_evening" onChange={that.handleValueChange} onBlur={that.saveData} />
                </td>
                <td>
                    <input className="imd-form-control imd-border-color-main imd-text-main" placeholder="Opis" value={that.state.data[key].description} data-key={key} data-properties="description" onChange={that.handleValueChange} onBlur={that.saveData} />
                </td>
            </tr>);
        });

        return rows;
    };

    /**
     * @returns {[]}
     */
    getChartData = () => {
        let rows = [];

        let that = this;
        Object.keys(this.state.data).forEach(function(key) {
            if (that.state.data[key].weight_1 !== null || that.state.data[key].weight_2 !== null) {
                rows.push({
                    date: that.state.data[key].date,
                    weight_1: that.state.data[key].weight_1,
                    weight_2: that.state.data[key].weight_2,
                    pressure_morning: that.state.data[key].pressure_morning,
                    pressure_evening: that.state.data[key].pressure_evening
                });
            }
        });

        return rows;
    };

    /**
     * Save device
     */
    saveData = () => {
        this.props.preloaderShow();
        let that = this;
        let month = that.state.month;
        let year = that.state.year;

        axios.put(config.apiAddress + '/app/fit/' + year + '/' + month, that.state.data)
            .then(function (response) {
                that.props.alert('Zmiany zostały zapisane');
                that.props.preloaderHide();
            }).catch(function(error) {
                that.props.alert('Nie udało się zapisać zmian');
                that.props.preloaderHide();
            });
    };

    /**
     * @param event
     */
    changeMonth = (event) => {
        let monthId = event.target.value;
        this.setState({month: monthId});
        this.loadData(monthId);
    };

    /**
     * @param event
     */
    changeYear = (event) => {
        let year = event.target.value;
        this.setState({year: year});
        this.loadData(this.state.month, year);
    };

    /**
     * @returns {XML}
     */
    render() {
        let years = [];
        let date = new Date();
        for (let d = date.getFullYear(); d > date.getFullYear() - 10; d--) {
            years.push(d);
        }

        return (
            <div className={this.getClass() + ' app imd-bg-transparent-default'} style={this.getStyle()} id="fit">
                <div className="title imd-bg-transparent-accent imd-text-default">
                    Zdrowie i sport
                </div>

                <div className="content">
                    <div className="imd-row">
                        <div className="imd-col-xs-8">
                            <select className="imd-form-control imd-border-color-main imd-text-main imd-bg-160-accent" onChange={this.changeMonth} value={this.state.month}>
                                {months.map((month, i) => <option value={month.id} key={i}>{month.name}</option>)}
                            </select>
                        </div>

                        <div className="imd-col-xs-4">
                            <select className="imd-form-control imd-border-color-main imd-text-main imd-bg-160-accent" onChange={this.changeYear} value={this.state.year}>
                                {years.map((year, i) => <option value={year} key={i}>{year}</option>)}
                            </select>
                        </div>
                    </div>

                    <div style={{height: '300px'}}>
                        <ResponsiveContainer width="100%" height={250}>
                            <LineChart data={this.getChartData()}>
                                <XAxis dataKey="date" />
                                <YAxis domain={['dataMin', 'auto']} />
                                <Line type="monotone" dataKey="weight_1" stroke="#ff6400" activeDot={{ r: 8 }} />
                                <Line type="monotone" dataKey="weight_2" stroke="#ff8300" />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>

                    <table className="imd-table imd-table-bordered imd-border-color-accent imd-text-accent">
                        <thead>
                        <tr>
                            <th>Data</th>
                            <th>Waga I</th>
                            <th>Waga II</th>
                            <th>Ciśnienie rano</th>
                            <th>Ciśnienie wieczorem</th>
                            <th>Opis</th>
                        </tr>
                        </thead>

                        <tbody>
                            {this.renderData()}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        email: state.login.email,
        device: state.login.device,
        user: state.user,
        view: state.view
    }
};
const mapDispatchToProps = { preloaderShow, preloaderHide, alert, login, userRefresh, setView };

export const FitScreenContainer = connect(mapStateToProps, mapDispatchToProps)(FitScreen);
