import React from 'react';
import { connect } from "react-redux";
import { config } from "../app/config";
import { AndraContainer } from './Andra';
import { IcyMatAssistantContainer } from './IcyMatAssistant';
import { andraStateNormal, andraStateThinking, setView, screenTalkAsk } from '../actions';
import '../styles/elements/main-panel.scss';

class MainPanel extends React.Component {
    /**
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            query: ''
        };
    }

    /**
     * @param event
     */
    handleKeyDown = (event) => {
        if (event.key === 'Enter' && this.state.query !== '') {
            this.props.setView('talk');
            this.props.screenTalkAsk(this.state.query);
            this.setState({query: ''});
        }
    };

    /**
     * @param event
     */
    handleQueryChange = (event) => {
        this.setState({query: event.target.value});
    };

    /**
     * @returns {*}
     */
    renderIcon = () => {
        if (config.icymat_assistant) {
            return (
                <IcyMatAssistantContainer activeIcon={true} />
            );
        }

        return (
            <AndraContainer activeIcon={true} />
        );
    };

    /**
     * @returns {*}
     */
    renderInput = () => {
        if (config.icymat_assistant) {
            return (
                <div>
                    <input type="text" className="query imd-form-control imd-border-color-accent imd-text-accent" value={this.state.query} onChange={this.handleQueryChange} onKeyDown={this.handleKeyDown} />
                </div>
            );
        }

        return (
            <div>
                <input type="text" className="query imd-form-control imd-border-color-accent imd-text-accent" value={this.state.query} onChange={this.handleQueryChange} onKeyDown={this.handleKeyDown} />
                {/*<Bugtracker productKey="c22a273cc84c685c9ca9d135e0637d87" />*/}
            </div>
        );
    };

    /**
     * @returns {XML}
     */
    render() {
        return (
            <div id="main-panel" className={"imd-bg-transparent-default"} style={{display: (this.props.email !== null ? 'block' : 'none')}}>
                {this.renderIcon()}
                {this.renderInput()}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        email: (state.user === null ? null : state.user.email)
    }
};

export const MainPanelContainer = connect(mapStateToProps, { andraStateNormal, andraStateThinking, setView, screenTalkAsk })(MainPanel);
