import React from 'react';
import { connect } from "react-redux";
import { NotificationsWidgetContainer } from "./main/NotificationsWidgetContainer";
import { WeatherWidgetContainer } from "./main/WeatherWidgetContainer";
import { ImportantInformationWidgetContainer } from "./main/ImportantInformationWidgetContainer";
import '../../styles/elements/screen.scss';
import '../../styles/elements/main-screen.scss';

export class MainScreen extends React.Component {
    /**
     * @returns {{display: string}}
     */
    getStyle() {
        return {
            display: (this.props.user !== null && this.props.user.name !== null) && (this.props.view === 'main' || this.props.view === null) ? 'block' : 'none'
        }
    }

    /**
     * @returns {string}
     */
    getName = () => {
        if (this.props.user !== null && 'name' in this.props.user && this.props.user.name !== null) {
            let name = this.props.user.name.split(' ');
            name = name[0];

            if (this.props.user.sex === 'female') {
                return 'Pani ' + name.substr(0, name.length - 1) + 'o';
            }

            //e, s, u,
            if (['sz', 'dz', 'ch', 'rz'].indexOf(name.substr(-2)) > -1) {
                return 'Panie ' + name + 'u';
            } else if (name.substr(-2) === 'ek') {
                return 'Panie ' + name.substr(0, name.length - 2) + 'ku';
            } else if (name.substr(-1) === 'a') {
                return 'Panie ' + name.substr(0, name.length - 1) + 'o';
            } else if (['b', 'f', 'm', 'n', 'p', 'w', 'z'].indexOf(name.substr(-1)) > -1) {
                return 'Panie ' + name + 'ie';
            } else if (['c', 'g', 'h', 'j', 'k', 'l'].indexOf(name.substr(-1)) > -1) {
                return 'Panie ' + name + 'u';
            } else if (name.substr(-1) === 'd') {
                return 'Panie ' + name + 'zie';
            } else if (name.substr(-1) === 'ł') {
                return 'Panie ' + name.substr(0, name.length - 1) + 'le';
            } else if (name.substr(-1) === 'r') {
                return 'Panie ' + name + 'ze';
            } else if (name.substr(-1) === 't') {
                return 'Panie ' + name.substr(0, name.length - 1) + 'cie';
            }

            //i, o
            return 'Panie ' + name;
        }

        return '';
    };

    render() {
        return (
            <div className="screen main-screen" style={this.getStyle()}>
                <h1 className="imd-text-main">Witam {this.getName()}</h1>

                <div className="imd-container-fluid">
                    <div className="imd-row">
                        <div className="imd-col-lg-4 imd-col-md-6 imd-col-xs-12">
                            <ImportantInformationWidgetContainer />
                            <WeatherWidgetContainer />
                        </div>

                        <div className="imd-col-lg-4 imd-col-md-6 imd-col-xs-12">
                            <NotificationsWidgetContainer />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user,
        view: state.view
    }
};
const mapDispatchToProps = {};

export const MainScreenContainer = connect(mapStateToProps, mapDispatchToProps)(MainScreen);
