import React from 'react';
import axios from 'axios';
import { preloaderShow, preloaderHide } from '../actions';
import { connect } from "react-redux";
import '../styles/elements/preloader.scss';

export class ConfirmationClass extends React.Component {
    /**
     * Constructor
     */
    constructor(props)
    {
        super(props);

        let type = 'api';
        if (this.props.type !== undefined) {
            type = this.props.type;
        }

        this.state = {
            address: this.props.address,
            type: type
        };
    }

    /**
     * @param nextProps
     */
    componentWillReceiveProps(nextProps) {
        if (nextProps.address !== this.state.address) {
            this.setState({address: nextProps.address});
        }
    }
    /**
     * @returns {*}
     */
    getClassName = () => {
        if (this.state.address !== null) {
            return 'imd-dialog imd-dialog-sm imd-bg-transparent-gray opened';
        }

        return 'imd-dialog imd-dialog-sm imd-bg-transparent-gray';
    };

    action = () => {
        let that = this;

        this.props.preloaderShow();
        axios.delete(this.state.address)
            .then(function (response) {
                that.setState({address: null});
                that.props.onSuccess(response, that.state.address);
                that.props.preloaderHide();
            }).catch(function(error) {
                that.setState({address: null});
                that.props.onError(error, that.state.address);
                that.props.preloaderHide();
            });
    };

    cancel = () => {
        this.setState({address: null});

        if (typeof this.props.onCancel !== 'undefined') {
            this.props.onCancel();

            return;
        }
    }

    /**
     * @returns {XML}
     */
    render() {
        return (
            <dialog className={this.getClassName()}>
                <div className="imd-dialog-body imd-bg-white">
                    <header>
                        Usuń
                        <span className="imd-icon imd-icon-cross imd-dialog-close" onClick={() => this.setState({address: null})} />
                    </header>

                    <div className="imd-dialog-content">
                        Czy na pewno chcesz usunąć ten element?
                    </div>

                    <footer>
                        <button className="imd-button imd-button-outline imd-border-color-default imd-text-default" onClick={this.cancel}>
                            Nie
                        </button>
                        <button className="imd-button imd-button-outline imd-border-color-main imd-text-main" onClick={() => this.action()}>
                            Tak
                        </button>
                    </footer>
                </div>
            </dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        email: state.login.email,
        device: state.login.device,
    }
};

export const Confirmation = connect(mapStateToProps, {preloaderShow, preloaderHide})(ConfirmationClass);
