import React from 'react';
import { months } from '../../LanguageComponent';
import { connect } from "react-redux";

export class CalendarMonth extends React.Component {
    /**
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            year: props.selectedDate.getFullYear(),
            month: props.selectedDate.getMonth(),
            show: props.show
        };
    }

    /**
     * Monday = 1, Sunday = 7
     *
     * @param date
     * @returns {number}
     */
    getDayNumber(date) {
        if (date.getDay() === 0) {
            return 7;
        }

        return date.getDay();
    }

    /**
     * @param date
     * @returns {*}
     */
    getDayClass(date)
    {
        let className = 'day';

        let full = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
        let today = new Date();
        today= today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        if (today === full) {
            className += ' today imd-text-main';
        }

        let dayNumber = date.getDay();
        if (dayNumber === 0) {
            dayNumber = 7;
        }

        if (this.props.dates.indexOf('daily') > -1) {
            return className + ' with-event';
        }

        if (this.props.dates.indexOf('weekly_' + dayNumber) > -1) {
            return className + ' with-event';
        }

        if (this.props.dates.indexOf('monthly_' + date.getDate()) > -1) {
            return className + ' with-event';
        }

        if (this.props.dates.indexOf('yearly_' + (date.getMonth() + 1) + '-' + date.getDate()) > -1) {
            return className + ' with-event';
        }

        if (this.props.dates.indexOf(full) > -1) {
            return className + ' with-event';
        }

        return className;
    }

    /**
     * @param firstWeekDay
     * @param month
     * @returns {Array}
     */
    createRow(firstWeekDay, month) {
        let rows = [];

        for (let i = this.getDayNumber(firstWeekDay); i > 1; i--) {
            let dayDate = new Date(firstWeekDay.getTime() - 86400000 * (i - 1));

            if (dayDate.getMonth() !== month) {
                rows.push(<div className="day" key={'d' + dayDate.getMonth() + '-' + dayDate.getDate()} />);
            } else {
                rows.push(<div className={this.getDayClass(dayDate)} key={'d' + dayDate.getMonth() + '-' + dayDate.getDate()} onClick={() => this.props.changeDate(dayDate)}>{dayDate.getDate()}</div>);
            }
        }

        let diff = 0;
        for (let i = this.getDayNumber(firstWeekDay); i <= 7; i++, diff++) {
            let dayDate = new Date(firstWeekDay.getTime() + 86400000 * diff);

            if (dayDate.getMonth() !== month) {
                rows.push(<div className="day" key={'d' + dayDate.getMonth() + '-' + dayDate.getDate()} />);
            } else {
                rows.push(<div className={this.getDayClass(dayDate)} key={'d' + dayDate.getMonth() + '-' + dayDate.getDate()} onClick={() => this.props.changeDate(dayDate)}>{dayDate.getDate()}</div>);
            }
        }

        return rows;
    }

    /**
     * @returns {Array}
     */
    createCalendar() {
        let rows = [];

        let days = ['Pn', 'Wt', 'Śr', 'Cz', 'Pt', 'So', 'Nd'];
        let daysRow = [];
        for (let x = 0; x < 7; x++) {
            daysRow.push(<div className="day weekday" key={'h' + x}>{days[x]}</div>);
        }
        rows.push(<div className="week headers" key="headers">{daysRow}</div>);

        let date = new Date(this.state.year, this.state.month, 1);

        for (let x = 0; x < 6; x++) {
            let weekDate = new Date(date.getTime() + 604800000 * x);
            rows.push(<div className="week" key={x}>{this.createRow(weekDate, date.getMonth())}</div>);
        }

        return (rows);
    };

    isOpen = () => {
        return (this.props.show ? ' opened' : null);
    };

    /**
     * Set next month preview
     */
    prevMonth = () => {
        let month = this.state.month;
        let year = this.state.year;

        month--;
        if (month < 0) {
            month = 11;
            year--;
        }

        this.setState({month: month, year: year});
    };

    /**
     * Set next month preview
     */
    nextMonth = () => {
        let month = this.state.month;
        let year = this.state.year;

        month++;
        if (month > 11) {
            month = 0;
            year++;
        }

        this.setState({month: month, year: year});
    };

    /**
     * @returns {XML}
     */
    render() {
        return (
            <dialog className={"imd-dialog imd-dialog-sm imd-bg-transparent-gray" + this.isOpen()}>
                <div className="imd-dialog-body imd-bg-contrast">
                    <header>
                        {months[this.state.month].name} {this.state.year}
                        <span className="imd-icon imd-icon-cross imd-dialog-close" onClick={() => this.props.hideMonth()}></span>
                    </header>

                    <div className="imd-dialog-content">
                        <button className="imd-button imd-button-sm imd-button-outline imd-border-color-accent imd-text-accent" onClick={this.prevMonth}>
                            &lt;&lt;
                        </button>

                        <button className="imd-button imd-button-sm imd-button-outline imd-border-color-accent imd-text-accent" style={{float: 'right'}} onClick={this.nextMonth}>
                            &gt;&gt;
                        </button>

                        <div className="calendar">
                            {this.createCalendar()}
                        </div>
                    </div>
                </div>
            </dialog>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        dates: state.calendarScreen.dates
    }
};

export const CalendarMonthContainer = connect(mapStateToProps, {})(CalendarMonth);
