import React from 'react';
import axios from 'axios';
import { config } from '../../../app/config';
import { connect } from "react-redux";
import { preloaderShow, preloaderHide, alert } from '../../../actions';

export class ToDoNewList extends React.Component {
    /**
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            name: ''
        }
    }

    /**
     * Create new list
     */
    createList = () => {
        this.props.preloaderShow();
        let that = this;

        axios.post(config.apiAddress + '/app/todo/list/new', {name: this.state.name})
            .then(function (response) {
                //I'm not hide poreloader because it will be hidden by CalendarEventsContainer after events will be reloaded.
                that.props.alert('Lista została utworzona');
                that.props.closeNewListDialog();
            }).catch(function(error) {
                that.props.preloaderHide();
                that.props.alert('Nie udało się utworzyć listy');
            });
    };

    /**
     * @returns {*}
     */
    isOpen = () => {
        return (this.props.show ? ' opened' : null);
    };

    /**
     * @returns {XML}
     */
    render() {
        return (
            <div>
                <dialog className={"imd-dialog imd-dialog-sm imd-bg-transparent-gray" + this.isOpen()}>
                    <div className="imd-dialog-body imd-bg-contrast">
                        <header>
                            Nowa lista
                            <span className="imd-icon imd-icon-cross imd-dialog-close" onClick={() => this.props.closeNewListDialog()} />
                        </header>

                        <div className="imd-dialog-content">
                            <div className="imd-row">
                                <label className="imd-col-xs-12">
                                    Nazwa listy
                                    <input type="text" className="imd-form-control imd-border-color-main imd-text-main" value={this.state.name} onChange={e => {this.setState({name: e.target.value});}} />
                                </label>
                            </div>
                        </div>

                        <footer>
                            <button className="imd-button imd-button-outline imd-border-color-default imd-text-default" onClick={() => this.props.closeNewListDialog()}>
                                Anuluj
                            </button>
                            <button className="imd-button imd-button-outline imd-border-color-main imd-text-main" onClick={this.createList}>
                                Zapisz
                            </button>
                        </footer>
                    </div>
                </dialog>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {}
};

export const ToDoNewListContainer = connect(mapStateToProps, {preloaderShow, preloaderHide, alert})(ToDoNewList);
